<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title *ngIf="this.IsPageView">Stock List</nz-page-header-title>
      <nz-page-header-subtitle *ngIf="this.IsPageView">View your stocks here</nz-page-header-subtitle>
      <nz-page-header-title *ngIf="this.IsPageInspection">Stock Inspection List</nz-page-header-title>
      <nz-page-header-subtitle *ngIf="this.IsPageInspection">Inspect your stocks here</nz-page-header-subtitle>
      <nz-page-header-title *ngIf="this.IsPageQualityInspection">Stock Quality Inspection List</nz-page-header-title>
      <nz-page-header-subtitle *ngIf="this.IsPageQualityInspection">Manage Quality Inspection of your stocks
        here</nz-page-header-subtitle>
      <nz-page-header-title *ngIf="this.IsPageAllocation">Stock Allocation List</nz-page-header-title>
      <nz-page-header-subtitle *ngIf="this.IsPageAllocation">Allocate your stocks here</nz-page-header-subtitle>
      <nz-page-header-title *ngIf="this.IsPageManageRejected">Stock Rejected List</nz-page-header-title>
      <nz-page-header-subtitle *ngIf="this.IsPageManageRejected">Manage Rejected items of your stocks
        here</nz-page-header-subtitle>
    </nz-page-header>
    <nz-divider></nz-divider>
    <nz-collapse>
      <nz-collapse-panel nzHeader="More Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
        <div nz-col [nzSpan]="24">
          <div nz-row [nzGutter]="24" style="margin: 0px 0px">
            <div nz-col [nzSpan]="24">
              <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Date Type</label>
                      <nz-select name="select-error" [(ngModel)]="request.DateType" (ngModelChange)="onFilterChange()" nzAllowClear>
                        <nz-option *ngFor="let data of dateTypeList" [nzValue]="data.Value"
                          [nzLabel]="data.Text"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label style="display:block">From Date</label>
                      <nz-date-picker class="form-select " [(ngModel)]="request.FromDate" nzPlaceHolder="From Date"
                        nzShowTime (ngModelChange)="onFilterChange()"></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label style="display:block">To Date</label>
                      <nz-date-picker class="form-select " [(ngModel)]="request.ToDate" nzPlaceHolder="To Date"
                        nzShowTime (ngModelChange)="onFilterChange()"></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>PO Number</label>
                      <input nz-input [(ngModel)]="request.PONumber" name="PONumber"
                        placeholder="Enter Full PO Number" (ngModelChange)="onFilterChange()"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Batch No.</label>
                      <input nz-input [(ngModel)]="request.BatchNo" name="BatchNo"
                        placeholder="Enter Full Batch Number" (ngModelChange)="onFilterChange()"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="5">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Supplier</label>
                      <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                        [(ngModel)]="request.SupplierId" name="SupplierId" nzAllowClear nzPlaceHolder="All" (ngModelChange)="onFilterChange()">
                        <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                          [nzLabel]="s.SupplierName"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Invoice Number</label>
                      <input nz-input [(ngModel)]="request.InvoiceNumber" name="InvoiceNumber"
                        placeholder="Enter Full Invoice Number" (ngModelChange)="onFilterChange()"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="3">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Product Type</label>
                      <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
                        (ngModelChange)="onSelectedProductTypeChange()" nzPlaceHolder="Choose" (ngModelChange)="onFilterChange()">
                        <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                        <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                        <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="6">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <label>Category</label>
                      <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                        [(ngModel)]="CategoryID" name="Category" nzAllowClear nzPlaceHolder="Category"
                        (ngModelChange)="GetAllFirstCategory($event); onFilterChange()">
                        <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                          [nzLabel]="s.ProductCategory"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="5">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>SubCategory</label>
                      <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                        [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Category"
                        (ngModelChange)="GetAllSecondCategory($event); onFilterChange()">
                        <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;"
                          [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="5">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>2nd SubCategory</label>
                      <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                        [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear
                        nzPlaceHolder="Second Category" (ngModelChange)="GetSecondCategoryFilteredProduct(); onFilterChange()">
                        <nz-option *ngFor="let s of this.ProductSecSubCategoryList;"
                          [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="7">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <label>Product Name</label>
                      <nz-select nzShowSearch class="form-select " nzSize="default" [(ngModel)]="request.ProductId"
                        name="ProductId" (ngModelChange)="onSelectedProductChange($event); onFilterChange()" nzAllowClear
                        nzPlaceHolder="Choose">
                        <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                          [nzLabel]="s.ProductName"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="9">
                  <nz-form-item>
                    <nz-form-control [nzSpan]="24">
                      <div style="border-bottom: 1px solid rgb(233, 233, 233);">
                        <label class="form-label" style="margin-right: 10px;">More Options: </label>
                        <label class="form-label" nz-checkbox [(ngModel)]="allChecked"
                          (ngModelChange)="updateAllChecked()" [nzIndeterminate]="indeterminate"> Check All</label>
                      </div>
                      <nz-checkbox-group [(ngModel)]="filterSummaryOptions"
                        (ngModelChange)="adjustFilterSummaryOptions($event); onFilterChange()"></nz-checkbox-group>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
          </div>
          <div style="float: right; margin-bottom: 2%; margin-right: 10px">
            <div style="display: flex">
              <button nz-button nzSize="small" nzType="primary" (click)="GetAllStocksWithFilters()">
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right">
      <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
        style="width: 150px;margin-right: 8px;"></nz-date-picker>
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input
        placeholder="Search invoice/date/Products/PONo" [(ngModel)]="searchValue" (keydown)="onKeydown($event)"
        (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>
    <nz-table #basicTable [nzScroll]="{ x: '1200px', y: '715px' }" nzShowSizeChanger style="width: 100%"
      [nzData]="this.FilteredStockList" [nzLoading]="this.isPageLoading" nzSize="small" [nzPageSize]="10" nzBordered
      [nzShowTotal]="totalListItemsTemplate" nzShowPagination nzShowQuickJumper>
      <thead>
        <tr>
          <th nzWidth="50px">ID</th>
          <th nzWidth="120px">Stock Date</th>
          <th nzWidth="160px">GRN</th>
          <th *ngIf="this.IsPageView" nzWidth="160px">Invoice No.</th>
          <th nzWidth="160px">PO No.</th>

          <th *ngIf="this.IsPageView" nzWidth="120px">Invoice Date</th>
          <th *ngIf="this.IsPageView" nzWidth="120px">Total Price</th>
          <th nzWidth="350px">Products</th>
          <th nzWidth="100px">Count</th>
          <th nzWidth="150px">Received By</th>
          <th nzWidth="150px">Received Date</th>
          <th nzWidth="90px" style="text-align: center" nzRight>Quality</th>
          <th nzWidth="110px" style="text-align: center" nzRight>Inspection</th>
          <th nzWidth="110px" style="text-align: center" nzRight>Allocation</th>
          <th nzWidth="100px" style="text-align: center" nzRight>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index">
          <td>{{ data.StockId }}</td>
          <td>{{ data.StockDate |DatetimeConverter}}</td>
          <td>{{ data.Invoice?.Grn }}</td>
          <td *ngIf="this.IsPageView">{{ data.Invoice?.InvoiceNumber }}</td>
          <td *ngIf="data.Invoice?.Poid && this.IsPageView">
            <span>
              <nz-tag [nzColor]="'blue'"><a (click)="OpenPODrawer(data.Invoice?.Poid)">
                  {{data.Invoice?.PONumber}}
                </a></nz-tag>
            </span>
          </td>
          <td *ngIf="data.Invoice?.Poid && !this.IsPageView">{{data.Invoice?.PONumber}}</td>
          <td *ngIf="!data.Invoice?.Poid"></td>

          <td *ngIf="this.IsPageView">{{ data.Invoice?.InvoiceDate | date : "dd-MMM-yyyy" }}</td>
          <td *ngIf="this.IsPageView">{{ data.Invoice?.InvoiceTotal }}</td>
          <td>{{ data.Products }}</td>
          <td>{{ data.Products.split(",").length }}</td>
          <td>{{ data.AddedBy }}</td>
          <td>{{ data.AddedDate | DatetimeConverter }}</td>
          <td style="text-align: center" nzRight>
            <div class="badge badge-light-danger" *ngIf="!data.IsQualityInspectionCompleted">
              In-Progress
            </div>
            <div class="badge badge-light-success" *ngIf="data.IsQualityInspectionCompleted">
              Completed
            </div>
          </td>
          <td style="text-align: center" nzRight>
            <div class="badge badge-light-danger" *ngIf="!data.InspectionCompleted">
              In-Progress
            </div>
            <div class="badge badge-light-success" *ngIf="data.InspectionCompleted">
              Completed
            </div>
          </td>
          <td style="text-align: center" nzRight>
            <div class="badge badge-light-danger" *ngIf="!data.AllocationCompleted">
              In-Progress
            </div>
            <div class="badge badge-light-success" *ngIf="data.AllocationCompleted">
              Completed
            </div>
          </td>
          <td style="text-align: center" nzRight>
            <div *ngIf="this.IsPageView">
              <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                class="btn btn-sm btn-light-primary" style="line-height:0">
                Action
                <span nz-icon nzType="down"></span>
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li style="width: 120px;" nz-menu-item routerLink="/home/stockdetails/{{ data.StockId }}">View</li>
                  <li style="width: 120px;" nz-menu-item *ngIf="this.IsPageView && data.IsOpeningStock"
                    routerLink="/home/updatestock/{{ data.StockId }}">Edit</li>
                  <li style="width: 120px;" nz-menu-item
                    *ngIf="this.IsPageView && this.permission.LabelPrint && data.IsTransferedStock == false"
                    (click)="OpenBarcodePrintModal(data.StockId)">Print Barcode</li>

                </ul>
              </nz-dropdown-menu>
            </div>

            <button routerLink="/home/stockinspection/{{ data.StockId }}" *ngIf="this.IsPageInspection"
              [disabled]="data.InspectionCompleted" class="btn btn-sm btn-light-primary">
              Inspect Stock
            </button>
            <button routerLink="/home/stockqualityinspection/{{ data.StockId }}" *ngIf="this.IsPageQualityInspection"
              [disabled]="data.IsQualityInspectionCompleted" class="btn btn-sm btn-light-primary">
              Manage Product Quality
            </button>
            <button routerLink="/home/stockproductallocation/{{ data.StockId }}" *ngIf="this.IsPageAllocation"
              [disabled]="!data.InspectionCompleted" class="btn btn-sm btn-light-primary">
              Product Allocation
            </button>
            <button routerLink="/home/stockmanagerejecteditems/{{ data.StockId }}" *ngIf="this.IsPageManageRejected"
              [disabled]="!data.InspectionCompleted" class="btn btn-sm btn-light-primary">
              Manage Rejected Products
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-modal [(nzVisible)]="IsBarcodePrintVisible" [nzStyle]="{ top: '50px'}" [nzWidth]="750" [nzTitle]="modalTitle"
  [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="handleBarcodePrintCancel()">
  <ng-template #modalTitle>Manage Product Barcode Label</ng-template>

  <ng-template #modalContent>
    <nz-table nzSize="small" [nzPageSize]="10" style="width: 100%;" #basicTable nzShowPagination="true"
      [nzData]="this.StockDetails.StockProduct" nzBordered [nzShowTotal]="totalTemplate">
      <thead>
        <tr>
          <th nzWidth="40px">S.No.</th>
          <th nzWidth="50px">Select</th>
          <th nzWidth="150px">Product Name</th>
          <th nzWidth="80px">Quantity</th>
          <th nzWidth="80px">Unit</th>
          <th nzWidth="180px">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data;let i = index">
          <td>{{data.SerialNo}}</td>
          <td>
            <input style="margin-right: 5px" type="checkbox" [checked]="data.IsChecked"
              (change)="onCheckboxChange(data)" [disabled]="DisableUncheckedProduct(data)" />
          </td>
          <td>{{ data.ProductName}}</td>
          <td>{{ data.Quantity}}</td>
          <td>{{ data.Unit}}</td>
          <td>
            <button *ngIf="data.IsChecked && data.IsBarcodeLabelGenerated" class="btn btn-sm btn-light-primary"
              (click)="rePrintAllBarcode()">Re-Print All</button>
            <button *ngIf="data.IsChecked && data.IsBarcodeLabelGenerated" class="btn btn-sm btn-light-primary"
              (click)="OpenSelectedLabelPrintModal()">Re-Print Selected</button>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div *ngIf="isAnyItemChecked" style="margin-top: 15px;text-align: center;">
      <label>Enter number of barcode labels required for selected Product</label>
    </div>
    <div *ngIf="isAnyItemChecked" style="margin-top: 15px;text-align: center;">
      <nz-input-number [(ngModel)]="numberOfLabels" [nzMin]="1" [nzMax]="100" [nzStep]="1"
        [nzPlaceHolder]="'Number of copies'"></nz-input-number>
    </div>
  </ng-template>

  <ng-template #modalFooter>

    <button nz-button nzType="default" (click)="handleBarcodePrintCancel()">Cancel</button>
    <button nz-button nzType="default" (click)="ResetCheckedProducts()">Reset</button>
    <button nz-button nzType="primary" *ngIf="permission.LabelAdd" (click)="printBarcodeConfirmation(numberOfLabels)" [nzLoading]="isLoading"
      [disabled]="!isAnyItemChecked">Save & Print</button>
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="IsSelectedLabelPrintVisible" [nzStyle]="{ top: '50px'}" [nzWidth]="850"
  [nzTitle]="modalTitleSelectedLabel" [nzContent]="modalContentSelectedLabel" [nzFooter]="modalFooterSelectedLabel"
  (nzOnCancel)="handleSelectedLabelPrintCancel()">
  <ng-template #modalTitleSelectedLabel>Manage Product Barcode Label</ng-template>

  <ng-template #modalContentSelectedLabel>

    <nz-table nzSize="small" [nzPageSize]="10" style="width: 100%;" #basicTable nzShowPagination="true"
      [nzData]="StockProductLabelList" nzBordered [nzShowTotal]="totalTemplate">
      <thead>
        <tr>
          <th nzWidth="50px">Select</th>
          <th nzWidth="40px">Serial No.</th>
          <th nzWidth="150px">Product Name</th>
          <th nzWidth="80px">Batch No.</th>
          <th nzWidth="100px">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data;let i = index">

          <td>
            <input style="margin-right: 5px" type="checkbox" [checked]="data.IsChecked"
              (change)="onSelectedLabelCheckboxChange(data)" />
          </td>
          <td>{{data.SerialNo}}</td>
          <td>{{ data.ProductName}}</td>
          <td>{{ data.BatchNo}}</td>
          <td [ngStyle]="{'background-color': SetLabelColor(data)}">{{ SetLabelStatus(data) }}</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>

  <ng-template #modalFooterSelectedLabel>

    <button nz-button nzType="default" (click)="handleSelectedLabelPrintCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="printSelectedLabel()" [nzLoading]="isLoading"
      [disabled]="!isAnyItemChecked">Print</button>
  </ng-template>
</nz-modal>

<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>
<ng-template #totalTemplateSelectedLabel let-total>Total {{ this.totalItemsCountSelectedLabel }} items</ng-template>
<ng-template #totalListItemsTemplate let-total>Total {{ this.totalListItemsCount }} items</ng-template>
<app-PoDrawer></app-PoDrawer>
<app-PoTimeline></app-PoTimeline>