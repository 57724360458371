import { Component, OnInit, OnDestroy, Inject, NgZone, PLATFORM_ID, ViewChild } from '@angular/core';
import { Subject, interval, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../Models/MasterModel';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { LoadingService } from '../../Services/loadingService';
import { HttpClient } from '@angular/common/http';
import { MfgHearbeatReport, MfgHeartbeatChartData, MfgHeartbeatTableData, SalesReportExportModel, SearchParamsReportModel } from '../../Models/ReportModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { formatDate, isPlatformBrowser } from '@angular/common';
import { DatetimeConverter } from 'src/PmsUIApp/Services/DatetimeConverter.pipe';
import { SoDrawerService } from 'src/PmsUIApp/Services/SoDrawerService';
import { ProductionDowntimeModel, ScheduledDowntimeModel } from 'src/PmsUIApp/Models/ProductionDowntime';
import { PrdDowntimeService } from 'src/PmsUIApp/Features/PrdDowntime/services/PrdDowntimeService';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-ManufacturingHeartbeatReport',
    templateUrl: './ManufacturingHeartbeatReport.component.html',
    styleUrls: ['./ManufacturingHeartbeatReport.component.css']
})

export class ManufacturingHeartbeatReportComponent implements OnInit, OnDestroy {
    ApiUrl = environment.Api_Url;
    isVisible = false;
    isLoading: boolean = false;
    isTableLoading: boolean = false;
    MfgHearbeatReportList: MfgHearbeatReport;
    MfgHearbeatReportListOriginal: MfgHearbeatReport[] = [];
    MfgHearbeatChartDataList: MfgHeartbeatChartData[];
    MfgHearbeatTableDataList: MfgHeartbeatTableData[];
    DowntimeDataList: ProductionDowntimeModel[] = [];
    ScheduledDowntimeDataList: ScheduledDowntimeModel[] = [];
    permission = {
        View: false,
        Add: false,
        Delete: false
    }
    ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
    ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
    ProductCategoryList: ProductCategoryModel[] = [];
    FilteredProductCategoryList: ProductCategoryModel[] = [];
    exportoptions = {
        headers: [
            "Order Date",
            "Order No.",
            "Item Name",
            "P.Code",
            "Grain Name",
            "Colors",
            "Width",
            "Fabric",
            "Thickness",
            "Finish",
            "Order QTY",
            "Pre Skin (GSM)",
            "Skin (GSM)",
            "Foam (GSM)",
            "Adhesive (GSM)",
            "Fabric (GSM)",
            "Party name",
            "Remarks",
            "Production Status",
            "Order Status",
            "Created Date",
            "Created By"
        ]
    };
    count: number;
    isEmailReportEnabled: boolean = false;
    isEmailButtonLoading: boolean = false;
    enableCustomDateRange: boolean = false;
    enableWorkShiftDropDown: boolean = false;

    ProductionLineList: any = [
        {
            "Text": "Line 1",
            "Value": 1
        },
        {
            "Text": "Line 2",
            "Value": 2
        }
    ];
    dateFilterOptions: any = [
        {
            "Text": "Day Shift (8AM to 8PM)",
            "Value": 'dayshift'
        },
        {
            "Text": "Night Shift (8PM to 8AM)",
            "Value": 'nightshift'
        },
        {
            "Text": "Today",
            "Value": 'today'
        },
        {
            "Text": "Yesterday",
            "Value": 'yesterday'
        },
        {
            "Text": "Last 7 Days",
            "Value": 'last7days'
        },
        {
            "Text": "Last 30 Days",
            "Value": 'last30days'
        },
        {
            "Text": "Last Month",
            "Value": 'lastmonth'
        },
        {
            "Text": "Last Year",
            "Value": 'lastyear'
        },
        {
            "Text": "Custom Range",
            "Value": 'custom'
        }
    ];
    WorkShiftList: any = [
        {
            "Text": "All",
            "Value": ''
        },
        {
            "Text": "Day Shift (8AM to 8PM)",
            "Value": 'day'
        },
        {
            "Text": "Night Shift (8PM to 8AM)",
            "Value": 'night'
        }
    ];
    ProductionLineTypeList: any = [
        {
            "Text": "All",
            "Value": 0
        },
        {
            "Text": "Line 1",
            "Value": 1
        }
    ];
    selecteddateFilter: string = 'today';
    previouslyselecteddateFilter: string = null;
    refreshInterval: number = 60; // Input for refresh interval in seconds
    autoRefreshSubscription: any;
    private unsubscribe$ = new Subject<void>();
    autoRefreshBtnText: string = 'Start Auto Refresh';
    totalItemsCount: number;
    totalDowntimeCount: number;
    totalScheduledDowntimeCount: number;
    totalUnknownDowntimeCount: number;
    private root1: am5.Root;
    IsMsgShow: boolean = false;
    IsDowntimeNoRecord: boolean = false;
    IsScheduledDowntimeNoRecord: boolean = false;
    IsUnknownDowntimeNoRecord: boolean = false;
    MfgHeartbeatRequest: SearchParamsReportModel = new SearchParamsReportModel();
    activeTab: string = 'chart'; // Default to chart view
    UnknownDowntimeDataList: any[];
    tableId: any;
    tablePageSize: number = 1000;
    pageIndex: number = 1;
    @ViewChild('basicTable2') basicTable2: any;

    constructor(@Inject(PLATFORM_ID) private platformId: any, private zone: NgZone, private loader: LoadingService, public http: HttpClient, private router: Router, private auth: AuthService,
        private message: NzMessageService, private soDrawer: SoDrawerService, private prdDowntimeService: PrdDowntimeService) {

    }
    ngOnInit() {
        this.permission.View = this.auth.CheckResponsibility(Modules.ReportsMfgHeartbeat, Responsibility.View);
        this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsMfgHeartbeat, Responsibility.Add);
        this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsMfgHeartbeat, Responsibility.Delete);
        if (this.permission.View != true) {
            this.router.navigate(['/home/unauthorized']);
        }
        this.getDateRange("today");
        this.selecteddateFilter = 'today';
        //this.startAutoRefresh();
        this.GetMfgHearbeatReportData()
    }

    getDateRange(label: string) {
        this.enableCustomDateRange = false;
        this.enableWorkShiftDropDown = false;
        this.MfgHeartbeatRequest.WorkShift = '';
        const today = new Date();
        let startDate: Date;
        let endDate: Date;

        switch (label) {
            case 'dayshift':
                startDate = new Date(new Date(new Date().setHours(8, 0, 0)))
                endDate = new Date(new Date(new Date().setHours(20, 0, 0)))
                this.MfgHeartbeatRequest.WorkShift = 'day'
                break;

            case 'nightshift':
                var currentHour = parseInt(new Date().getHours().toString());
                if (currentHour >= 0 && currentHour <= 8) {
                    startDate = new Date(new Date(new Date().setHours(20, 0, 0)).setDate(new Date().getDate() - 1))
                    endDate = new Date(new Date().setHours(8, 0, 0))
                }
                else {
                    startDate = new Date(new Date().setHours(20, 0, 0))
                    endDate = new Date(new Date(new Date().setHours(8, 0, 0)).setDate(new Date().getDate() + 1))
                }
                this.MfgHeartbeatRequest.WorkShift = 'night'
                break;

            case 'today':
                startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(today.getDate()));
                endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
                this.enableWorkShiftDropDown = true;
                break;

            case 'yesterday':
                startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 1));
                // startDate.setDate(today.getDate() - 1);
                endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(new Date().getDate() - 1));
                // endDate.setDate(today.getDate() - 1);
                this.enableWorkShiftDropDown = true;
                break;

            case 'last7days':
                // startDate = new Date(today);
                // startDate.setDate(today.getDate() - 6);
                // endDate = new Date(today);
                startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 6));
                // startDate.setDate(today.getDate() - 1);
                endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
                // endDate.setDate(today.getDate() - 1);
                this.enableWorkShiftDropDown = true;
                break;

            case 'last30days':
                startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 29));
                endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
                this.enableWorkShiftDropDown = true;
                break;

            case 'lastmonth':
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0);
                endDate = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
                this.enableWorkShiftDropDown = true;
                break;

            case 'lastyear':
                startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate(), 0, 0, 0);
                endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
                this.enableWorkShiftDropDown = true;
                break;

            case 'custom':
                startDate = new Date(today);
                endDate = new Date(today);
                this.enableCustomDateRange = true;
                this.enableWorkShiftDropDown = true;
                break;

            default:
                startDate = new Date();
                endDate = new Date();
                break;
        }

        this.MfgHeartbeatRequest.DateFrom = startDate.toISOString();
        this.MfgHeartbeatRequest.DateTo = endDate.toISOString();

    }

    setAutoRefreshTime(seconds: number) {
        this.refreshInterval = seconds;
        this.message.info('Refresh Interval set to ' + seconds + ' seconds')
        this.startAutoRefresh();
    }

    startAutoRefresh(): void {
        this.autoRefreshSubscription = interval(this.refreshInterval * 1000)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.GetMfgHearbeatReportData();
            });
    }

    setAutoRefreshAction(): void {
        if (!this.autoRefreshSubscription?.closed) {
            this.autoRefreshSubscription.unsubscribe();
            this.autoRefreshBtnText = 'Start Auto Refresh';
            this.message.info('Auto refresh stopped');
        }
        else {

            this.startAutoRefresh();
            this.message.info('Auto refresh started');
            this.autoRefreshBtnText = 'Stop Auto Refresh';
        }
    }

    space(el: any) {
        if (el.target.selectionStart === 0 && el.code === "Space") {
            el.preventDefault();
        }
    }
    onFilterPanelOpen(data: any) {
        if (data == true) {

        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.browserOnly(() => {
            if (this.root1) {
                this.root1.dispose();
            }
        });
    }
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }
    GetMfgHeartbeatChart() {
        if (this.root1) {
            this.root1.dispose();
        };
        this.root1 = am5.Root.new("mfgheartbeatdiv");

        this.root1.setThemes([
            am5themes_Animated.new(this.root1)
        ]);

        let chart = this.root1.container.children.push(am5xy.XYChart.new(this.root1, {
            panX: true,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true
        }));

        let cursor = chart.set("cursor", am5xy.XYCursor.new(this.root1, {
            behavior: "zoomX"
        }));
        cursor.lineY.set("visible", false);

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(this.root1, {
            baseInterval: { timeUnit: "minute", count: 1 },
            renderer: am5xy.AxisRendererX.new(this.root1, {}),
            tooltip: am5.Tooltip.new(this.root1, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.root1, {
            renderer: am5xy.AxisRendererY.new(this.root1, {})
        }));

        let series = chart.series.push(am5xy.ColumnSeries.new(this.root1, {
            name: "Production Line Status",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            openValueXField: "openDate"
        }));

        series.columns.template.setAll({
            strokeOpacity: 0,
            tooltipY: 0
        });

        // Add this custom tooltip renderer
        series.columns.template.set("tooltipText", "{status}");
        // Create a custom tooltip
        let tooltip = am5.Tooltip.new(this.root1, {
            getFillFromSprite: false,  // Disable inheriting color from column
            labelText: "{status} for {reason}: {openDate.formatDate('HH:mm:ss','en-US')} to {date.formatDate('HH:mm:ss', 'en-US')}",
            autoTextColor: false  // Disable auto text color
        });

        // Set tooltip styles
        tooltip.set("background", am5.Rectangle.new(this.root1, {
            fill: am5.color(0x000000),  // Black background
            fillOpacity: 0.8,           // 80% opacity
            stroke: am5.color(0xffffff) // White border
        }));

        // Set tooltip text color
        tooltip.label.setAll({
            fill: am5.color(0xffffff),  // White text
            fontSize: 12
        });

        // Apply tooltip to series
        series.columns.template.setAll({
            tooltipY: 0,
            tooltip: tooltip
        });
        series.columns.template.adapters.add("fill", (fill, target) => {
            const status = (target.dataItem.dataContext as { status: string }).status;

            switch (status) {
                case "Running":
                    return am5.color("#00ff00");  // Green
                case "Scheduled Downtime":
                    return am5.color("#0000ff");  // Dark Blue
                case "Acceptable Downtime":
                    return am5.color("#4169E1");  // Royal Blue
                case "Production Down":
                case "Excess Downtime":
                    return am5.color("#ff0000");  // Red
                default:
                    return am5.color("#FFFF00");  // Yellow for unknown status
            }
        });

        // Set data
        let data = this.MfgHearbeatChartDataList.map(item => ({
            date: new Date(item.endTime).getTime(),
            openDate: new Date(item.startTime).getTime(),
            value: 1,
            status: item.status,
            reason: item.reason
        }));
        series.data.setAll(data);

        chart.set("scrollbarX", am5.Scrollbar.new(this.root1, {
            orientation: "horizontal"
        }));

        series.appear(1000);
        chart.appear(1000, 100);
    }

    ResetTableChart() {
        this.MfgHearbeatChartDataList = [];
        this.MfgHearbeatTableDataList = [];
        this.DowntimeDataList = [];
    }

    GetMfgHearbeatReportData() {
        this.isTableLoading = true;
        this.IsMsgShow = false;
        this.IsDowntimeNoRecord = false;
        let url = this.ApiUrl + "report/getmfghearbeatreport";

        this.http.post<MfgHearbeatReport>(url, this.MfgHeartbeatRequest).subscribe({
            next: res => {
                this.MfgHearbeatReportList = res;
                this.MfgHearbeatChartDataList = res.ChartData;
                this.MfgHearbeatTableDataList = res.TableData;
                this.DowntimeDataList = res.DowntimeData;
                this.ScheduledDowntimeDataList = res.ScheduledDowntimeData;
                this.UnknownDowntimeDataList = res.UnknownDowntimeData.filter(x => moment(x.EndDateTime).diff(moment(x.StartDateTime), 'minutes') > 5);
                if (this.MfgHearbeatTableDataList.length > 0) {

                    let count = this.totalItemsCount = 0;
                    this.MfgHearbeatTableDataList.forEach((x) => {
                        count++
                        x.SerialNo = count;
                        x.ProcessDate = formatDate(x.ProcessDate.toString(), 'dd-MM-yyyy', "en-US");
                        x.StartTime = new Date(new DatetimeConverter().transform(x.StartTime.toString()));
                        x.EndTime = new Date(new DatetimeConverter().transform(x.EndTime.toString()));
                    })
                    this.totalItemsCount = count;
                }
                else {
                    this.IsMsgShow = true;
                }

                if (this.MfgHearbeatChartDataList.length > 0 && this.activeTab === 'chart') {
                    this.GetMfgHeartbeatChart();
                }
                if (this.DowntimeDataList.length > 0) {
                    let count = this.totalDowntimeCount = 0;
                    this.DowntimeDataList.forEach((x) => {
                        count++;
                        x.SerialNo = count;
                    })
                    this.totalDowntimeCount = count;
                }
                else {
                    this.IsDowntimeNoRecord = true;
                }
                if (this.ScheduledDowntimeDataList.length > 0) {
                    let count = this.totalScheduledDowntimeCount = 0;
                    this.ScheduledDowntimeDataList.forEach((x) => {
                        count++;
                        x.SerialNo = count;
                        x.StartDateTime = moment(new Date(x.StartDateTime)).format('DD-MMM-YYYY hh:mm A');
                        x.EndDateTime = moment(new Date(x.EndDateTime)).format('DD-MMM-YYYY hh:mm A');
                    })
                    this.totalScheduledDowntimeCount = count;
                    this.IsScheduledDowntimeNoRecord = false;
                }
                else {
                    this.IsScheduledDowntimeNoRecord = true;
                }
                if (this.UnknownDowntimeDataList.length > 0) {
                    let count = this.totalUnknownDowntimeCount = 0;
                    this.UnknownDowntimeDataList.forEach((x) => {
                        count++;
                        x.SerialNo = count;
                        x.StartDateTime = moment(new Date(x.StartDateTime)).format('DD-MMM-YYYY hh:mm A');
                        x.EndDateTime = moment(new Date(x.EndDateTime)).format('DD-MMM-YYYY hh:mm A');
                        x.Duration = this.convertToMinutesHours(x);
                    })
                    this.totalUnknownDowntimeCount = count;
                    this.IsUnknownDowntimeNoRecord = false;
                }
                else {
                    this.IsUnknownDowntimeNoRecord = true;
                }
                this.isTableLoading = false;
            },
            error: res => {
                this.count++;
                if (this.count < 2) { this.GetMfgHearbeatReportData(); }
            }
        });
    }

    OpenSaleOrderDetailViewPop(data: any) {
        this.soDrawer.SaleOrderId = data;
        this.soDrawer.show();
    }

    onTabChange(index: number): void {
        this.activeTab = index === 0 ? 'chart' : 'table';
        if (this.activeTab === 'chart') {
            this.GetMfgHeartbeatChart();
        }
    }
    OpenPrdDowntimeModal(data: any) {
        var model = new ProductionDowntimeModel();
        model.StartTime = data.StartDateTime;
        model.EndTime = data.EndDateTime;
        model.ProductionLineNo = Number(this.MfgHeartbeatRequest.ProductionLineNo);
        model.ProductionLineType = this.MfgHeartbeatRequest.ProductionLineType;
        this.prdDowntimeService.openModal("Manufacturing", model);
    }
    convertToMinutesHours(data: any) {
        var minutes = moment(new Date(data.EndDateTime)).diff(moment(data.StartDateTime), 'minutes');
        var hours = Math.floor(minutes / 60);
        var remainingMinutes = minutes % 60;
        return hours + " Hours " + remainingMinutes + " Minutes" + " (" + minutes + " Minutes)";
    }

    async exportChartToPDF() {
        try {
            const element = document.getElementById('mfgheartbeatdiv');
            if (!element) return;

            const canvas = await html2canvas(element, {
                scale: 2,
                useCORS: true,
                logging: false
            });

            const pdf = new jsPDF('l', 'mm', 'a4');

            // Add color legend horizontally
            pdf.setFontSize(14);
            pdf.text('Production Line Heartbeat Report', 15, 15);
            pdf.setFontSize(10);
            pdf.text(this.getReportTitle(), 15, 22);

            // Add color legend horizontally with reduced spacing
            const legendY = 30; // Reduced from 45
            const boxSize = 4;  // Reduced from 5
            const textOffset = 5; // Reduced from 7
            let currentX = 15;

            // Running (Green)
            pdf.setFillColor(0, 255, 0);
            pdf.rect(currentX, legendY, boxSize, boxSize, 'F');
            pdf.text('Running', currentX + boxSize + 2, legendY + textOffset);
            currentX += 30;

            // Schedule Downtime (Dark Blue)
            pdf.setFillColor(0, 0, 255);
            pdf.rect(currentX, legendY, boxSize, boxSize, 'F');
            pdf.text('Schedule Downtime', currentX + boxSize + 2, legendY + textOffset);
            currentX += 55;

            // Excess Downtime (Red)
            pdf.setFillColor(255, 0, 0);
            pdf.rect(currentX, legendY, boxSize, boxSize, 'F');
            pdf.text('Excess Downtime', currentX + boxSize + 2, legendY + textOffset);
            currentX += 45;

            // Acceptable Downtime (Royal Blue)
            pdf.setFillColor(65, 105, 225);
            pdf.rect(currentX, legendY, boxSize, boxSize, 'F');
            pdf.text('Acceptable Downtime', currentX + boxSize + 2, legendY + textOffset);
            currentX += 55;

            // Production Down (Red)
            pdf.setFillColor(255, 0, 0);
            pdf.rect(currentX, legendY, boxSize, boxSize, 'F');
            pdf.text('Production Down', currentX + boxSize + 2, legendY + textOffset);
            currentX += 45;

            // Waiting for Production Data (Yellow)
            pdf.setFillColor(255, 255, 0);
            pdf.rect(currentX, legendY, boxSize, boxSize, 'F');
            pdf.text('Waiting for Production Data', currentX + boxSize + 2, legendY + textOffset);

            // Add chart with reduced spacing
            const imgData = canvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 15, legendY + 10, 267, 150); // Reduced spacing before chart


            pdf.save('manufacturing-heartbeat-chart.pdf');
        } catch (error) {
            console.error('Error exporting chart:', error);
            this.message.error('Error exporting chart to PDF');
        }
    }

    async exportTableToPDF(
        tableId: string,
        title: string,
        filename: string,
        headers: string[]
    ) {
        // Store original state
        const originalPageSize = this.tablePageSize;
        const originalPageIndex = this.pageIndex;
        try {
            const element = document.getElementById(tableId);
            if (!element) return;

            // Get all data first
            let allData: any[] = [];
            switch (tableId) {
                case 'downtime-table':
                    allData = [...this.DowntimeDataList];
                    break;
                case 'scheduled-downtime-table':
                    allData = [...this.ScheduledDowntimeDataList];
                    break;
                case 'unknown-downtime-table':
                    allData = [...this.UnknownDowntimeDataList];
                    break;
            }


            // Force table to show all data
            this.tablePageSize = allData.length;
            this.basicTable2.data = allData;
            await this.waitForChanges();

            // Store original table state
            const originalTable = element.cloneNode(true) as HTMLElement;
            this.prepareTableForExport(element);

            const pdf = new jsPDF('l', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const margin = 15;
            const headerHeight = 35;

            // Get all table rows
            const tbody = element.querySelector('tbody');
            const allRows = tbody?.querySelectorAll('tr');
            if (!allRows) return;

            // Calculate available height for content
            const availableHeight = pageHeight - headerHeight - (2 * margin);
            const dataRowHeight = 15;
            const rowSpacing = 2;

            // Calculate rows that can fit on a page
            const rowsPerPage = Math.floor(availableHeight / (dataRowHeight + rowSpacing));
            const totalPages = Math.ceil(allRows.length / rowsPerPage);

            // Process each page
            for (let page = 0; page < totalPages; page++) {
                if (page > 0) {
                    pdf.addPage();
                }

                // Add header to each page
                pdf.setFontSize(14);
                pdf.text(title, margin, margin);
                pdf.setFontSize(10);
                pdf.text(this.getReportTitle(), margin, margin + 7);
                pdf.text(`Page ${page + 1} of ${totalPages}`, pageWidth - 50, margin);

                // Hide all rows first
                Array.from(allRows).forEach(row => {
                    (row as HTMLElement).style.display = 'none';
                });

                // Show table header and current page rows
                const startRow = page * rowsPerPage;
                const endRow = Math.min((page + 1) * rowsPerPage, allRows.length);

                // Show header row
                const headerRow = element.querySelector('thead tr');
                if (headerRow) {
                    (headerRow as HTMLElement).style.display = '';
                }

                // Show data rows for current page
                for (let i = startRow; i < endRow; i++) {
                    (allRows[i] as HTMLElement).style.display = '';
                }

                await this.waitForChanges();

                // Capture current page
                const canvas = await html2canvas(element, {
                    scale: 1.5,
                    useCORS: true,
                    logging: false,
                    backgroundColor: '#ffffff',
                    imageTimeout: 0,
                    removeContainer: true,
                });

                const imgWidth = pageWidth - (2 * margin);
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                // Add image with compression
                pdf.addImage(
                    canvas.toDataURL('image/jpeg', 0.7),
                    'JPEG',
                    margin,
                    headerHeight,
                    imgWidth,
                    imgHeight,
                    undefined,
                    'FAST'
                );
            }

            // Restore original table state
            element.parentNode?.replaceChild(originalTable, element);

            // Restore original state and refresh data
            this.tablePageSize = originalPageSize;
            this.pageIndex = originalPageIndex;
            this.GetMfgHearbeatReportData();

            pdf.save(filename);
        } catch (error) {
            console.error('Error exporting table:', error);
            this.message.error('Error exporting table to PDF');

            // Restore original state in case of error
            this.tablePageSize = originalPageSize;
            this.pageIndex = originalPageIndex;
            this.GetMfgHearbeatReportData();
        }
    }

    private prepareTableForExport(element: HTMLElement) {
        const table = element.querySelector('.ant-table');
        if (table) {
            const scrollBody = table.querySelector('.ant-table-body');
            if (scrollBody) {
                (scrollBody as HTMLElement).style.maxHeight = 'none';
                (scrollBody as HTMLElement).style.overflow = 'visible';
            }

            const virtualScroll = table.querySelector('cdk-virtual-scroll-viewport');
            if (virtualScroll) {
                (virtualScroll as HTMLElement).style.height = 'auto';
            }
        }

        // Hide pagination
        const pagination = element.querySelector('.ant-pagination');
        if (pagination) {
            (pagination as HTMLElement).style.display = 'none';
        }

        // Force table to show all rows
        const tableContainer = element.querySelector('.ant-table-container');
        if (tableContainer) {
            (tableContainer as HTMLElement).style.height = 'auto';
            (tableContainer as HTMLElement).style.maxHeight = 'none';
        }
    }

    exportToExcel(data: any[], headers: { [key: string]: string }, filename: string) {
        try {
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
                data.map(item => {
                    const row: any = {};
                    Object.keys(headers).forEach(key => {
                        if (key === 'EffectiveFrom' || key === 'EffectiveTo' || key === 'StartTime' || key === 'EndTime' || key === 'CreatedOn' || (key === 'ModifiedOn' && item.ModifiedOn)) {
                            row[headers[key]] = moment(new Date(new DatetimeConverter().transform(item[key].toString())))
                                .format('DD-MMM-YYYY hh:mm A');
                        } else {
                            row[headers[key]] = item[key];
                        }
                    });
                    return row;
                })
            );

            const workbook: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
            XLSX.writeFile(workbook, `${filename}.xlsx`);
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            this.message.error('Error exporting to Excel');
        }
    }

    // Specific export functions for each table
    exportUnknownDowntimeToPDF() {
        this.exportTableToPDF(
            'unknown-downtime-table',
            'Unknown Downtime Report',
            'unknown-downtime-report.pdf',
            ['S.NO.', 'Start Time', 'End Time', 'Duration']
        );
    }

    exportUnknownDowntimeToExcel() {
        const headers = {
            'SerialNo': 'S.NO.',
            'StartDateTime': 'Start Time',
            'EndDateTime': 'End Time',
            'Duration': 'Duration',
        };
        this.exportToExcel(this.UnknownDowntimeDataList, headers, 'unknown-downtime-report');
    }
    exportDowntimeToPDF() {
        this.exportTableToPDF(
            'downtime-table',
            'Downtime Report',
            'downtime-report.pdf',
            ['S.NO.', 'Reason', 'Reason (Code)', 'Start Time', 'End Time', 'PRD Line Type', 'Line No.', 'Actual Duration', 'Standard Duration', 'Excess Duration', 'Comments', 'Created On', 'Created By', 'Last Updated Date', 'Last Updated By']
        );
    }
    exportDowntimeToExcel() {
        const headers = {
            'SerialNo': 'S.NO.',
            'ReasonName': 'Reason',
            'ReasonCode': 'Reason (Code)',
            'StartTime': 'Start Time',
            'EndTime': 'End Time',
            'ProductionLineType': 'PRD Line Type',
            'ProductionLineNo': 'Line No.',
            'ActualDurationFormatted': 'Actual Duration',
            'StandardDurationFormatted': 'Standard Duration',
            'ExcessDurationFormatted': 'Excess Duration',
            'Comments': 'Comments',
            'CreatedOn': 'Created On',
            'CreatedBy': 'Created By',
            'ModifiedOn': 'Last Updated Date',
            'ModifiedBy': 'Last Updated By',
        };
        this.exportToExcel(this.DowntimeDataList, headers, 'downtime-report');
    }
    exportScheduledDowntimeToPDF() {
        this.exportTableToPDF(
            'scheduled-downtime-table',
            'Scheduled Downtime Report',
            'scheduled-downtime-report.pdf',
            ['S.NO.', 'Reason (Code)', 'Start Time', 'End Time', 'PRD Line Type', 'Line No.', 'Effective From', 'Effective To']
        );
    }
    exportScheduledDowntimeToExcel() {
        const headers = {
            'SerialNo': 'S.NO.',
            'Reason': 'Reason (Code)',
            'StartDateTime': 'Start Time',
            'EndDateTime': 'End Time',
            'ProductionLineType': 'PRD Line Type',
            'ProductionLineNo': 'Line No.',
            'EffectiveFrom': 'Effective From',
            'EffectiveTo': 'Effective To',
        };
        this.exportToExcel(this.ScheduledDowntimeDataList, headers, 'scheduled-downtime-report');
    }

    private getReportTitle(): string {
        return `Line Type: ${this.MfgHeartbeatRequest.ProductionLineType} | Line No: ${this.MfgHeartbeatRequest.ProductionLineNo} | Period: ${moment(this.MfgHeartbeatRequest.DateFrom).format('DD-MMM-YYYY hh:mm A')} to ${moment(this.MfgHeartbeatRequest.DateTo).format('DD-MMM-YYYY hh:mm A')}`;
    }

    private waitForChanges(): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, 100));
    }
}
