import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StockModel, StockPrintModel } from 'src/PmsUIApp/Models/StockModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import * as moment from 'moment';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { StockProductModel } from 'src/PmsUIApp/Models/StockProductModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import { StockLabelResponseModel } from 'src/PmsUIApp/Models/BarcodeLabelModel';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SupplierModel } from '../../Models/SupplierModel';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../Models/MasterModel';
import { ProductModel } from '../../Models/ProductModel';
import { PoDrawerService } from '../../Features/PoDrawerView/services/PoDrawerService';


@Component({
  templateUrl: './StockList.component.html',
})
export class StockListComponent implements OnInit {
  StockList: StockModel[];
  FilteredStockList: StockModel[];
  FilteredStockListPage: StockModel[];
  ApiUrl = environment.Api_Url;
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  selectedInspectionStatus: string;
  IsPageView: boolean = false;
  IsPageAllocation: boolean = false;
  IsPageQualityInspection: boolean = false;
  IsPageInspection: boolean = false;
  IsPageManageRejected: boolean = false;
  isPageLoading: boolean = false;
  exportoptions = {
    headers: [
      'STOCK DATE',
      'INVOICE NO.',
      'INVOICE DATE',
      "GRN",
      'TOTAL PRICE',
      'PRODUCTS',
      'COUNT',
      'INSPECTION',
      'ALLOCATION',
    ],
  };
  fields: StockPrintModel = new StockPrintModel();
  exportfields: StockPrintModel[] = [];
  count: 0;
  request = {
    IsOpeningStock: null,
    DateType: 'stockdate', //addeddate, stockdate, invoicedate, podate
    FromDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    ToDate: new Date(new Date().setHours(23, 59, 59)),
    ProductType: '',
    ProductId: 0,
    ProductCategoryId: 0,
    ProductFirstSubCategoryId: 0,
    ProductSecSubCategoryId: 0,
    IsStockTransferIncluded: false,
    StockType: '', //Opening, Purchase
    PONumber: '',
    InvoiceNumber: '',
    SupplierId: 0,
    IsInspectionCompleted: null,
    IsAllocationCompleted: null,
    IsQualityInspectionCompleted: null,
    BatchNo: ''
  }
  permission = {
    View: false,
    InspectionView: false,
    AllocationView: false,
    QualityInspectionView: false,
    ManageRejectedView: false,
    Add: false,
    Delete: false,
    LabelPrint: false,
    LabelAdd: false,
  }
  StockDetails: StockModel;
  totalItemsCount: number = 0;
  totalItemsCountSelectedLabel: number = 0;
  IsBarcodePrintVisible: boolean = false;
  isAnyItemChecked: boolean = false;
  SelectedStockProduct: StockProductModel;
  numberOfLabels: number = 1;
  isLoading: boolean = false;
  IsSelectedLabelPrintVisible: boolean = false;
  StockProductLabelList: StockLabelResponseModel[] = [];
  FilteredProductList: ProductModel[];
  ProductList: ProductModel[];
  SelectedProductType: string = '';
  ProductCategoryList: ProductCategoryModel[];
  CategoryID: number = 0;
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[];
  FirstCategoryID: number = 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[];
  SecondCategoryID: number = 0;
  SupplierList: SupplierModel[];
  dateTypeList = [
    {
      "Text": "Stock Date",
      "Value": 'stockdate'
    },
    {
      "Text": "Added Date",
      "Value": 'addeddate'
    },
    {
      "Text": "PO Date",
      "Value": 'podate'
    },
    {
      "Text": "Invoice Date",
      "Value": 'invoicedate'
    }];
  filterSummaryOptions = [
    { label: 'Inspection Completed', value: 'inspectioncompleted', checked: false, disabled: false },
    { label: 'Inspection Pending', value: 'inspectionpending', checked: false, disabled: false },
    { label: 'Allocation Completed', value: 'allocationcompleted', checked: false, disabled: false },
    { label: 'Allocation Pending', value: 'allocationpending', checked: false, disabled: false },
    { label: 'Quality Inspection Completed', value: 'qualityinspectioncompleted', checked: false, disabled: false },
    { label: 'Quality Inspection Pending', value: 'qualityinspectionpending', checked: false, disabled: false },
    { label: 'Purchase Order Stock', value: 'purchaseorderstock', checked: false, disabled: false },
    { label: 'Opening Stock', value: 'openingstock', checked: false, disabled: false },
    { label: 'Transfered Stocks', value: 'transferedstocks', checked: false, disabled: false },

  ];
  indeterminate: boolean = false;
  allChecked: boolean = false;
  totalListItemsCount: number = 0;

  constructor(
    public http: HttpClient,
    public router: ActivatedRoute,
    public routerNav: Router,
    private alertService: AlertMessageService,
    private auth: AuthService,
    private loader: LoadingService,
    private modalService: NzModalService,
    private poDrawer: PoDrawerService
  ) {
    this.StockList = [];
    this.FilteredStockList = [];
    this.FilteredStockListPage = [];
    this.selectedInspectionStatus = '';
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  GetAllStocks() {
    this.isPageLoading = true;
    let url = this.ApiUrl + 'stock/getallstocks';
    this.http.get<StockModel[]>(url).subscribe(
      (res) => {
        this.StockList = res;
        console.log(` this.StockList `, this.StockList)
        this.isPageLoading = false;
        this.FilteredStockList = res;
        if (this.IsPageManageRejected) {
          this.FilteredStockList = this.FilteredStockList.filter(x => (x.ManageRejectedItemsCompleted == false || x.ManageRejectedItemsCompleted == null) && x.AllocationCompleted == true);
        }
        if (this.IsPageAllocation) {
          this.FilteredStockList = this.FilteredStockList.filter(x => (x.AllocationCompleted == false || x.AllocationCompleted == null) && x.InspectionCompleted == true);
        }
        if (this.IsPageInspection) {
          this.FilteredStockList = this.FilteredStockList.filter(x => (x.InspectionCompleted == false || x.InspectionCompleted == null));
        }
        this.FilteredStockListPage = this.FilteredStockList;
        this.setExportData(this.FilteredStockList);
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllStocks();
        }
      }
    );
  }
  GetAllStocksWithFilters() {
    this.isPageLoading = true;
    let url = this.ApiUrl + 'stock/getallstockswithfilters';
    let count = this.totalListItemsCount = 0;
    this.http.post<StockModel[]>(url, this.request).subscribe(
      (res) => {
        this.StockList = res;

        this.isPageLoading = false;
        this.FilteredStockList = res;
        if (this.IsPageManageRejected) {
          this.FilteredStockList = this.FilteredStockList.filter(x => (x.ManageRejectedItemsCompleted == false) && x.AllocationCompleted == true);
        }
        if (this.IsPageAllocation) {
          // this.filterSummaryOptions.find(x => x.value == 'allocationpending').checked = true;
          this.FilteredStockList = this.FilteredStockList.filter(x => (x.AllocationCompleted == false || x.AllocationCompleted == null) && x.InspectionCompleted == true);
        }
        if (this.IsPageInspection) {
          this.FilteredStockList = this.FilteredStockList.filter(x => (x.InspectionCompleted == false || x.InspectionCompleted == null) && x.IsQualityInspectionCompleted == true);
        }
        if (this.IsPageQualityInspection) {
          this.FilteredStockList = this.FilteredStockList.filter(x => (x.IsQualityInspectionCompleted == false || x.IsQualityInspectionCompleted == null));
        }
        this.FilteredStockListPage = this.FilteredStockList;
        this.setExportData(this.FilteredStockList);
        this.FilteredStockListPage.forEach(x => {
          count++;
        });
        this.totalListItemsCount = count;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllStocksWithFilters();
        }
      }
    );
  }
  onFilterChange() {
    sessionStorage.setItem('stocklistrequestfilters', JSON.stringify(this.request));
  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(this.exportfields, 'stock-export' + exportdate, this.exportoptions);
  }
  SearchChange() {
    if (this.selectedInspectionStatus == 'all') {
      this.FilteredStockList = this.StockList;

    }
    if (this.selectedInspectionStatus == 'progress') {
      this.FilteredStockList = this.StockList.filter(
        (x) => x.InspectionCompleted == false
      );

    }
    if (this.selectedInspectionStatus == 'Completed') {
      this.FilteredStockList = this.StockList.filter(
        (x) => x.InspectionCompleted == true
      );

    }
  }

  SetPageType() {
    var pgurl = this.routerNav.url;
    if (pgurl == '/home/stocklist') {
      this.IsPageView = true;
    }

    if (pgurl == '/home/stockinspectionlist') {
      this.IsPageInspection = true;
    }
    if (pgurl == '/home/stockqualityinspectionlist') {
      this.IsPageQualityInspection = true;
    }
    if (pgurl == '/home/stockallocationlist') {
      this.IsPageAllocation = true;
    }
    if (pgurl == '/home/stockmanagerejectedlist') {
      this.IsPageManageRejected = true;
    }
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.StockList, Responsibility.View);
    this.permission.InspectionView = this.auth.CheckResponsibility(Modules.Inspection, Responsibility.View);
    this.permission.AllocationView = this.auth.CheckResponsibility(Modules.Allocation, Responsibility.View);
    this.permission.QualityInspectionView = this.auth.CheckResponsibility(Modules.StockQualityInspection, Responsibility.View);
    this.permission.ManageRejectedView = this.auth.CheckResponsibility(Modules.Rejected, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.StockList, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.StockList, Responsibility.Delete);
    this.permission.LabelAdd = this.auth.CheckResponsibility(Modules.StockBarcodeLabel, Responsibility.Add);
    this.permission.LabelPrint = this.auth.CheckResponsibility(Modules.StockBarcodeLabel, Responsibility.View);
    if (this.permission.View != true && this.IsPageView) {
      this.routerNav.navigate(['/home/unauthorized']);
    }
    if (this.permission.InspectionView != true && this.IsPageInspection) {
      this.routerNav.navigate(['/home/unauthorized']);
    }
    if (this.permission.QualityInspectionView != true && this.IsPageQualityInspection) {
      this.routerNav.navigate(['/home/unauthorized']);
    }
    if (this.permission.AllocationView != true && this.IsPageAllocation) {
      this.routerNav.navigate(['/home/unauthorized']);
    }
    if (this.permission.ManageRejectedView != true && this.IsPageManageRejected) {
      this.routerNav.navigate(['/home/unauthorized']);
    }
    this.SetPageType();
    // this.GetAllStocks();
    const existingFilters = sessionStorage.getItem('stocklistrequestfilters');
    if (existingFilters) {
      this.request = JSON.parse(existingFilters);
    }
    this.GetAllStocksWithFilters();
  }
  searchValue = '';
  visible = false;
  reset(): void {
    this.searchValue = '';
    this.search();
    // this.GetAllStocks();
    this.GetAllStocksWithFilters();
  }


  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.FilteredStockListPage;
    var res2 = this.FilteredStockListPage
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: StockModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.StockDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.StockDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.FilteredStockList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.FilteredStockList = res.filter(
        (item: StockModel) =>
          item?.Products?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.Invoice.InvoiceTotalPrice == +this.searchValue ||
          item?.Grn?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.Invoice.PONumber?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          item.Invoice.InvoiceNumber.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          item.StockId == +this.searchValue
      )
    }
    this.setExportData(this.FilteredStockList);

  }

  setExportData(data: StockModel[]) {
    this.exportfields = [];
    data.forEach((x) => {
      this.fields = new StockPrintModel();

      this.fields.StockDate = x.StockDate;
      this.fields.GRN = x.Invoice.Grn;
      this.fields.InvoiceNumber = x.Invoice?.InvoiceNumber;
      this.fields.InvoiceDate = x.Invoice?.InvoiceDate;
      this.fields.InvoiceTotalPrice = x.Invoice?.InvoiceTotalPrice;
      this.fields.Products = x.Products;
      this.fields.Count = x.Products.split(',').length;
      this.fields.Inspection =
        x.InspectionCompleted == true ? 'Completed' : 'In-Progress';
      this.fields.Allocation =
        x.AllocationCompleted == true ? 'Completed' : 'In-Progress';

      this.exportfields.push(this.fields);
    });
  }
  GetStockDetails(StockId: number) {
    this.loader.show();
    let url = this.ApiUrl + "stock/getstockbyid/" + StockId;
    let count = this.totalItemsCount = 0;
    this.http.get<StockModel>(url).subscribe(res => {
      this.StockDetails = res;
      this.StockDetails.StockProduct.forEach(x => {
        count++;
        x.SerialNo = count;
      });
      this.totalItemsCount = count;
      this.IsBarcodePrintVisible = true;
      this.initializeCheckboxState();
      this.loader.hide();
    }, res => { this.loader.hide() });
  }
  onCheckboxChange(data: StockProductModel) {
    // If this item is being unchecked, allow all items to be selectable again
    if (data.IsChecked) {
      data.IsChecked = false;
      this.isAnyItemChecked = false;
      this.SelectedStockProduct = null;
      return;
    }

    // Uncheck all other items
    this.StockDetails.StockProduct.forEach(item => {
      if (item !== data) {
        item.IsChecked = false;
      }
    });

    // Check this item
    data.IsChecked = true;
    this.isAnyItemChecked = true;
    this.SelectedStockProduct = data;
  }
  DisableUncheckedProduct(data: StockProductModel): boolean {
    // If no item is checked, allow all items to be selectable
    if (!this.isAnyItemChecked) {
      return false;
    }

    // If this item is checked, it should remain enabled
    if (data.IsChecked) {
      return false;
    }

    // If another item is checked, disable this one
    return true;
  }
  handleBarcodePrintCancel() {
    this.IsBarcodePrintVisible = false;
  }
  printBarcodeConfirmation(NumberOfLabels: number) {
    const modal1 = this.modalService.confirm({
      nzTitle: 'New Label Confirmation',
      nzContent: 'This will create ' + NumberOfLabels + ' new label. Are you sure to proceed? If you are trying to reprint barcode, please click on re-print button.',
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => this.printBarcode(NumberOfLabels),
    });
    setTimeout(() => modal1.destroy(), 30000);
  }
  printBarcode(NumberOfLabels: number) {
    this.isLoading = true;
    window.open(`${window.location.origin}/barcodelabel/` + this.SelectedStockProduct.StockProductId + '/' + this.SelectedStockProduct.StockId + '/' + this.SelectedStockProduct.ProductId + '/' + NumberOfLabels + '/' + 1);
    sessionStorage.setItem('generatenewbarcode', 'true');
    this.GetStockDetails(this.StockDetails.StockId);
    this.isLoading = false;
  }
  OpenBarcodePrintModal(StockId: number) {
    this.GetStockDetails(StockId);
  }
  ResetCheckedProducts() {
    this.StockDetails.StockProduct.forEach(x => {
      x.IsChecked = false;
    });
    this.isAnyItemChecked = false;
    this.numberOfLabels = 1;
  }
  initializeCheckboxState() {
    this.isAnyItemChecked = this.StockDetails.StockProduct.some(item => item.IsChecked);
    if (this.isAnyItemChecked) {
      this.SelectedStockProduct = this.StockDetails.StockProduct.find(item => item.IsChecked);
    }
  }
  rePrintAllBarcode() {
    this.isLoading = true;
    window.open(`${window.location.origin}/barcodelabel/` + this.SelectedStockProduct.StockProductId + '/' + this.SelectedStockProduct.StockId + '/' + this.SelectedStockProduct.ProductId + '/' + 0 + '/' + 2);
    this.isLoading = false;
  }
  OpenSelectedLabelPrintModal() {
    this.getAllStockProductLabel();
  }
  getAllStockProductLabel() {
    this.loader.show();
    let url = this.ApiUrl + "stock/getstocklabelsbybatchproductid";
    this.totalItemsCountSelectedLabel = 0;
    this.http.post(url, this.SelectedStockProduct).subscribe({
      next: (res: any) => {
        this.StockProductLabelList = res;
        this.totalItemsCountSelectedLabel = this.StockProductLabelList.length;
        this.loader.hide();
        this.IsSelectedLabelPrintVisible = true;
        console.log(res);
      }, error: (err: any) => {
        this.loader.hide();
        console.log(err);
      }
    })
  }
  handleSelectedLabelPrintCancel() {
    this.IsSelectedLabelPrintVisible = false;
  }
  onSelectedLabelCheckboxChange(data: StockLabelResponseModel) {
    // Toggle the checked state of the item
    data.IsChecked = !data.IsChecked;

    // Update isAnyItemChecked based on whether any item is checked
    this.isAnyItemChecked = this.StockProductLabelList.some(item => item.IsChecked);
  }

  printSelectedLabel() {
    this.isLoading = true;

    // Filter the checked labels
    const selectedLabelsId = this.StockProductLabelList.filter(label => label.IsChecked).map(label => label.StockLabelId);

    if (selectedLabelsId.length === 0) {
      this.alertService.error("Please select at least one label to print.");
      this.isLoading = false;
      return;
    }

    // Store the selected labels in session storage
    sessionStorage.setItem('selectedLabelsIdList', JSON.stringify(selectedLabelsId));

    // Open the print page in a new window
    window.open(`${window.location.origin}/barcodelabel/` + 3);

    this.isLoading = false;
    this.handleSelectedLabelPrintCancel(); // Close the modal after initiating print
  }
  SetLabelStatus(data: StockLabelResponseModel) {
    if (data.IsActive && data.Quantity > 0) {
      return 'Active';
    } else if (!data.IsActive && (data.Quantity == 0 || data.Quantity == null)) {
      return 'Pending';
    } else {
      return 'In-Active';
    }
  }
  SetLabelColor(data: StockLabelResponseModel) {
    if (data.IsActive && data.Quantity > 0) {
      return '#96f0b1';
    } else if (!data.IsActive && (data.Quantity == 0 || data.Quantity == null)) {
      return 'yellow';
    } else {
      return 'red';
    }
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllSupplier();
      this.GetAllProducts();
    }
  }
  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
    this.GetAllProductCategory();
  }
  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
        this.loader.hide();
      },
      (res) => {
        this.count++;
        this.loader.hide();
        if (this.count < 2) {
          this.GetAllProducts();
        }
      }
    );
  }
  GetAllProductCategory() {
    this.loader.show();
    let url = this.ApiUrl + 'productcategory/getallproductcategoriesforlisting';
    this.http.get<ProductCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductCategoryList = res;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
      }
    );
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductCategoryId == this.CategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == data
        );
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
      }
    );
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductFirstSubCategoryId == this.FirstCategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductSecSubCategoryList = res.filter(
          (x) => x.ProductFirstSubCategoryId == data
        );
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
      }
    );
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductSecSubCategoryId == this.SecondCategoryID
    );
  }
  onSelectedProductChange(data: any) {
    var fp = this.FilteredProductList.filter(
      (x) => x.ProductId == data
    )[0];
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == this.CategoryID
        );
        this.FirstCategoryID = fp.ProductFirstSubCategoryId;
        let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
          (res) => {
            this.ProductSecSubCategoryList = res.filter(
              (x) => x.ProductFirstSubCategoryId == this.FirstCategoryID
            );
            this.SecondCategoryID = fp.ProductSecSubCategoryId;
          },
          (res) => { }
        );
      },
      (res) => { }
    );
  }
  GetAllSupplier() {
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllSupplier(); }
    });
  }
  OpenPODrawer(PoId: number) {
    // this.isPODrawerVisible = true;
    this.poDrawer.Poid = PoId;
    this.poDrawer.show();
  }
  adjustFilterSummaryOptions($event: any[]) {
    if (this.filterSummaryOptions.every(item => !item.checked)) {
      this.allChecked = false;
      this.indeterminate = false;
    } else if (this.filterSummaryOptions.every(item => item.checked)) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
    this.SetFilterSummaryOptions();
  }
  SetFilterSummaryOptions() {
    if (this.filterSummaryOptions.find(x => x.value == 'openingstock').checked) {
      this.request.StockType = 'Opening'
    } else if (this.filterSummaryOptions.find(x => x.value == 'purchaseorderstock').checked) {
      this.request.StockType = 'Purchase'
    } else {
      this.request.StockType = '';
    }

    if (this.filterSummaryOptions.find(x => x.value == 'inspectioncompleted').checked) {
      this.request.IsInspectionCompleted = true
    } else if (this.filterSummaryOptions.find(x => x.value == 'inspectionpending').checked) {
      this.request.IsInspectionCompleted = false
    } else {
      this.request.IsInspectionCompleted = null;
    }

    if (this.filterSummaryOptions.find(x => x.value == 'allocationcompleted').checked) {
      this.request.IsAllocationCompleted = true
    } else if (this.filterSummaryOptions.find(x => x.value == 'allocationpending').checked) {
      this.request.IsAllocationCompleted = false
    } else {
      this.request.IsAllocationCompleted = null;
    }

    if (this.filterSummaryOptions.find(x => x.value == 'qualityinspectioncompleted').checked) {
      this.request.IsQualityInspectionCompleted = true
    } else if (this.filterSummaryOptions.find(x => x.value == 'qualityinspectionpending').checked) {
      this.request.IsQualityInspectionCompleted = false
    } else {
      this.request.IsQualityInspectionCompleted = null;
    }

    if (this.filterSummaryOptions.find(x => x.value == 'transferedstocks').checked) {
      this.request.IsStockTransferIncluded = true
    } else {
      this.request.IsStockTransferIncluded = false;
    }
  }
  updateAllChecked(): void {
    this.indeterminate = false;
    if (this.allChecked) {
      this.filterSummaryOptions = this.filterSummaryOptions.map(item => ({
        ...item,
        checked: true
      }));
    } else {
      this.filterSummaryOptions = this.filterSummaryOptions.map(item => ({
        ...item,
        checked: false
      }));
    }
    this.SetFilterSummaryOptions();
  }
}
