import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AlertMessageService } from './AlertMessageService';
import { ColorModel, GrainModel, ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, ThicknessModel } from '../Models/MasterModel';
import { GeneralConfigModel } from '../Models/GeneralConfiguration';
import { ProductionDowntimeReasonModel } from '../Models/ProductionDowntime';
import { CustomerModel } from '../Models/SupplierModel';
import { ProductModel } from '../Models/ProductModel';
import { FormulationCodeModel } from '../Models/FormulationCodeModel';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private ApiUrl = environment.Api_Url;

    // Cache storage for dropdown data
    private dropdownCache: { [key: string]: any[] } = {};

    // BehaviorSubjects for different dropdowns
    private productionDowntimeReasonList = new BehaviorSubject<ProductionDowntimeReasonModel[]>([]);
    private thicknessList = new BehaviorSubject<ThicknessModel[]>([]);
    private customerList = new BehaviorSubject<CustomerModel[]>([]);
    private formulationCodeList = new BehaviorSubject<FormulationCodeModel[]>([]);
    private colorList = new BehaviorSubject<ColorModel[]>([]);
    private grainList = new BehaviorSubject<GrainModel[]>([]);
    private productCategoryList = new BehaviorSubject<ProductCategoryModel[]>([]);
    private productList = new BehaviorSubject<ProductModel[]>([]);
    private productFirstSubCategoryList = new BehaviorSubject<ProductFirstSubCategoryModel[]>([]);
    private productSecondSubCategoryList = new BehaviorSubject<ProductSecSubCategoryModel[]>([]);
    
    private generalConfigList = new BehaviorSubject<GeneralConfigModel[]>([]);
    private configvalue = new BehaviorSubject<string>('');

    constructor(
        private http: HttpClient,
        private alertService: AlertMessageService
    ) { }

    // Generic method to load and cache dropdown data
    private loadDropdownData(endpoint: string, subject: BehaviorSubject<any[]>, cacheKey: string): void {
        // Return cached data if available
        if (this.dropdownCache[cacheKey]) {
            subject.next(this.dropdownCache[cacheKey]);
            return;
        }

        try {
            this.http.get<any>(`${this.ApiUrl}${endpoint}`).subscribe({
                next: (response) => {
                    this.dropdownCache[cacheKey] = response;
                    subject.next(response);
                },
                error: (error) => {
                    this.alertService.error('Error loading dropdown data');
                    console.error('Dropdown loading error:', error);
                }
            });
        } catch (error) {
            this.alertService.error('Error loading dropdown data');
            console.error('Dropdown loading error:', error);
        }
    }

    private loadConfigDataByConfigItem(endpoint: string, subject: BehaviorSubject<string>, configItem: string): void {
        this.http.post<GeneralConfigModel>(`${this.ApiUrl}${endpoint}`, { ConfigItem: configItem }).subscribe({
            next: (response) => {
                subject.next(response.ConfigValue);
            },
            error: (error) => {
                this.alertService.error('Error loading dropdown data');
                console.error('Dropdown loading error:', error);
            }
        });
    }

    // Production Downtime Reasons
    getProductionDowntimeReasons(): Observable<ProductionDowntimeReasonModel[]> {
        this.loadDropdownData(
            'productiondowntime/getproductiondowntimereasonlist',
            this.productionDowntimeReasonList,
            'downtimeReasons'
        );
        return this.productionDowntimeReasonList.asObservable();
    }

    // Thickness List
    getThicknessList(): Observable<ThicknessModel[]> {
        this.loadDropdownData(
            'thickness/getallthicknessdata',
            this.thicknessList,
            'thickness'
        );
        return this.thicknessList.asObservable();
    }

    // Customer List
    getCustomerList(): Observable<CustomerModel[]> {
        this.loadDropdownData(
            'customer/getallcustomers',
            this.customerList,
            'customer'
        );
        return this.customerList.asObservable();
    }

    // Formulation Code List
    getFormulationCodeList(): Observable<FormulationCodeModel[]> {
        this.loadDropdownData(
            'saleorder/getallformulationcodesforfilter',
            this.formulationCodeList,
            'formulationCode'
        );
        return this.formulationCodeList.asObservable();
    }

    // Color List
    getColorList(): Observable<ColorModel[]> {
        this.loadDropdownData(
            'Color/getallColors',
            this.colorList,
            'color'
        );
        return this.colorList.asObservable();
    }   

    // Grain List
    getGrainList(): Observable<GrainModel[]> {
        this.loadDropdownData(
            'Grain/getallGrains',
            this.grainList,
            'grain'
        );
        return this.grainList.asObservable();
    }

    // Product Category List
    getProductCategoryList(): Observable<ProductCategoryModel[]> {
        this.loadDropdownData(
            'productcategory/getallproductcategoriesforlisting',
            this.productCategoryList,
            'productCategory'
        );
        return this.productCategoryList.asObservable();
    }

    // Product List
    getProductList(): Observable<ProductModel[]> {
        this.loadDropdownData(
            'product/getallproducts',
            this.productList,
            'product'
        );
        return this.productList.asObservable();
    }

    // Product Sub Category List
    getProductFirstSubCategoryList(): Observable<ProductFirstSubCategoryModel[]> {
        this.loadDropdownData(
            'productcategory/getallproductfirstsubcategories',
            this.productFirstSubCategoryList,
            'productFirstSubCategory'
        );
        return this.productFirstSubCategoryList.asObservable();
    }

    // Product Second Sub Category List
    getProductSecondSubCategoryList(): Observable<ProductSecSubCategoryModel[]> {
        this.loadDropdownData(
            'productcategory/getallproductsecsubcategories',
            this.productSecondSubCategoryList,
            'productSecondSubCategory'
        );
        return this.productSecondSubCategoryList.asObservable();
    }

    // General Config List
    getGeneralConfigList(): Observable<GeneralConfigModel[]> {
        this.loadDropdownData(
            'generalconfig/getconfig',
            this.generalConfigList,
            'generalConfig'
        );
        return this.generalConfigList.asObservable();
    }

    // Get Config Value
    getConfigValue(configItem: string): Observable<string> {
        this.loadConfigDataByConfigItem(
            'generalconfig/getconfigbyconfigitem',
            this.configvalue,
            configItem
        );
        return this.configvalue.asObservable();
    }

    // Clear specific cache
    clearCache(key: string): void {
        if (this.dropdownCache[key]) {
            delete this.dropdownCache[key];
        }
    }

    // Clear all cache
    clearAllCache(): void {
        this.dropdownCache = {};
    }
} 