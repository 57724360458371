<app-SoDrawer></app-SoDrawer>
<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title> Production Line Heartbeat Report</nz-page-header-title>
            <nz-page-header-subtitle>Showing Status of the Production Line</nz-page-header-subtitle>
        </nz-page-header>
        <nz-tabset nzSize="large">
            <nz-tab nzTitle="Production Lines Heartbeat">

                <div nz-col [nzSpan]="24">
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Date Filters</label>
                                    <nz-select [(ngModel)]="selecteddateFilter"
                                        (ngModelChange)="getDateRange(selecteddateFilter)" nzPlaceHolder="Choose"
                                        [ngModelOptions]="{standalone: true}">
                                        <nz-option *ngFor="let data of dateFilterOptions" [nzValue]="data.Value"
                                            [nzLabel]="data.Text"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4" *ngIf="this.enableCustomDateRange">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label> From Date</label>
                                    <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                                        [(ngModel)]="MfgHeartbeatRequest.DateFrom" nzShowTime
                                        nzAllowClear></nz-date-picker>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4" *ngIf="this.enableCustomDateRange">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label> To Date</label>
                                    <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                                        [(ngModel)]="MfgHeartbeatRequest.DateTo" nzShowTime
                                        nzAllowClear></nz-date-picker>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Line Type</label>
                                    <nz-select class="form-select mb-2" nzShowSearch name="ProductionLineType"
                                        nzSize="default" nzPlaceHolder="Choose"
                                        [(ngModel)]="MfgHeartbeatRequest.ProductionLineType"
                                        [ngModelOptions]="{standalone: true}">
                                        <nz-option nzValue="Manufacturing" nzLabel="Manufacturing"></nz-option>
                                        <nz-option nzValue="Printing" nzLabel="Printing"></nz-option>
                                        <nz-option nzValue="Embossing" nzLabel="Embossing"></nz-option>
                                        <nz-option nzValue="Lacquer" nzLabel="Lacquer"></nz-option>
                                        <nz-option nzValue="Vacuum" nzLabel="Vacuum"></nz-option>
                                        <!-- <nz-option nzValue="Packaging" nzLabel="Packaging"></nz-option> -->
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4"
                            *ngIf="enableWorkShiftDropDown && MfgHeartbeatRequest.ProductionLineType == 'Manufacturing'">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Work Shift</label>
                                    <nz-select [(ngModel)]="MfgHeartbeatRequest.WorkShift" nzAllowClear
                                        nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let data of WorkShiftList" [nzValue]="data.Value"
                                            [nzLabel]="data.Text"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Production Line No.</label>
                                    <nz-select [(ngModel)]="MfgHeartbeatRequest.ProductionLineNo"
                                        nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let data of ProductionLineList" [nzValue]="data.Value"
                                            [nzLabel]="data.Text"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="2">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label style="visibility: hidden;">Apply Filter</label>
                                    <button nz-button nzType="primary" (click)="GetMfgHearbeatReportData()">
                                        Apply Filter
                                    </button>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="2">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label style="visibility: hidden;">Refresh</label>
                                    <button nz-button nzType="primary" (click)="GetMfgHearbeatReportData()"
                                        style="width: 100%;">
                                        Refresh
                                    </button>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </div>
                <nz-divider></nz-divider>
                <nz-tabset (nzSelectedIndexChange)="onTabChange($event)">
                    <nz-tab nzTitle="Chart View">
                        <div class="export-buttons">
                            <button nz-button nzType="primary" (click)="exportChartToPDF()">
                                <i nz-icon nzType="file-pdf"></i>Export Chart as PDF
                            </button>
                        </div>
                        <div class="card-body pt-5">
                            <!-- Color Legend -->
                            <div class="color-legend mb-4">
                                <nz-tag [nzColor]="'#00ff00'">
                                    <span class="legend-text-black">Running</span>
                                </nz-tag>
                                <nz-tag [nzColor]="'#0000ff'">
                                    <span class="legend-text-white">Schedule Downtime</span>
                                </nz-tag>
                                <nz-tag [nzColor]="'#ff0000'">
                                    <span class="legend-text-white">Excess Downtime</span>
                                </nz-tag>
                                <nz-tag [nzColor]="'#4169E1'">
                                    <span class="legend-text-white">Acceptable Downtime</span>
                                </nz-tag>
                                <nz-tag [nzColor]="'#ff0000'">
                                    <span class="legend-text-white">Production Down</span>
                                </nz-tag>
                                <nz-tag [nzColor]="'#FFFF00'">
                                    <span class="legend-text-black">Waiting for Production Data</span>
                                </nz-tag>
                            </div>
                            <div id="mfgheartbeatdiv" class="w-100 h-400px"></div>
                        </div>
                    </nz-tab>
                    <nz-tab nzTitle="Unknown Downtime Details">
                        <div class="export-buttons" *ngIf="this.UnknownDowntimeDataList?.length > 0">
                            <button nz-button nzType="primary" (click)="exportUnknownDowntimeToPDF()">
                                <i nz-icon nzType="file-pdf"></i>Export as PDF
                            </button>
                            <button nz-button nzType="default" (click)="exportUnknownDowntimeToExcel()">
                                <i nz-icon nzType="file-excel"></i>Export to Excel
                            </button>
                        </div>
                        <nz-alert nzCloseable nzType="info" *ngIf="IsUnknownDowntimeNoRecord"
                            nzMessage="No unknown downtime has been found for the selected date range."></nz-alert>
                        <nz-divider></nz-divider>
                        <div style="max-width:100%; overflow:scroll;border-color: black;"
                            *ngIf="this.UnknownDowntimeDataList?.length > 0">
                            <nz-table id="unknown-downtime-table" nzSize="small"
                                style="width: 100%;page-break-inside: auto;" [nzScroll]="{ x: '800px',y:'515px' }"
                                [nzData]="this.UnknownDowntimeDataList" #basicTable2 [nzLoading]="isTableLoading"
                                nzBordered nzShowPagination="true" nzShowSizeChanger [nzPageSize]="500"
                                [nzPageSizeOptions]=[20,50,100,200,500] [nzShowTotal]="totalUnknownDowntimeTemplate"
                                nzShowQuickJumper="true" nzResponsive=true>
                                <thead>
                                    <tr
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto;">
                                        <th nzWidth="50px">S. NO.</th>
                                        <th nzWidth="120px">Start Date Time</th>
                                        <th nzWidth="80px">End Date Time</th>
                                        <th nzWidth="80px">Duration</th>
                                        <th nzWidth="80px">Action</th>
                                    </tr>
                                    <tr>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of basicTable2.data;"
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto">
                                        <td style="border-color: black;">{{data.SerialNo}}</td>
                                        <td style="border-color: black;">{{ data.StartDateTime}}
                                        </td>
                                        <td style="border-color: black;">{{ data.EndDateTime}}</td>
                                        <td style="border-color: black;">{{ data.Duration}}</td>
                                        <td style="border-color: black;">
                                            <button nz-button nzType="primary" (click)="OpenPrdDowntimeModal(data)">
                                                Add Downtime Reason
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-tab>
                    <nz-tab nzTitle="Production Details">
                        <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow"
                            nzMessage="No production data found for the selected date range."></nz-alert>
                        <nz-divider></nz-divider>
                        <div style="max-width:100%; overflow:scroll;border-color: black;"
                            *ngIf="this.MfgHearbeatTableDataList?.length > 0">
                            <nz-table id="production-details-table" nzSize="small"
                                style="width: 100%;page-break-inside: auto;" [nzScroll]="{ x: '800px',y:'515px' }"
                                [nzData]="this.MfgHearbeatTableDataList" #basicTable2 [nzLoading]="isTableLoading"
                                nzBordered nzShowPagination="true" nzShowSizeChanger [nzPageSize]="500"
                                [nzPageSizeOptions]=[20,50,100,200,500] [nzShowTotal]="totalTemplate"
                                nzShowQuickJumper="true" nzResponsive=true>
                                <thead>
                                    <tr
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto;">
                                        <th nzWidth="50px">S. No.</th>
                                        <th nzWidth="80px">Sale Order</th>
                                        <th nzWidth="80px">Process No.</th>
                                        <th nzWidth="80px">Process Date</th>
                                        <th nzWidth="80px">Process QTY</th>
                                        <th nzWidth="80px">Start Time</th>
                                        <th nzWidth="80px">End Time</th>
                                        <th nzWidth="80px">Line No.</th>
                                        <th nzWidth="80px">Work Shift</th>
                                        <th nzWidth="80px">Shift Sup.</th>
                                        <th nzWidth="80px">Added Time</th>
                                    </tr>
                                    <tr>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of basicTable2.data;"
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto">
                                        <td style="border-color: black;">{{data.SerialNo}}</td>
                                        <td style="border-color: black;">
                                            <span>
                                                <nz-tag [nzColor]="'blue'"><a
                                                        (click)="OpenSaleOrderDetailViewPop(data.SaleOrderId)">
                                                        {{data.SaleOrderNumber}}
                                                    </a></nz-tag>
                                            </span>
                                        </td>
                                        <td style="border-color: black;">{{ data.ProcessNo }} </td>
                                        <td style="border-color: black;">{{ data.ProcessDate }}</td>
                                        <td style="border-color: black;">{{ data.ProcessQty }} MTRs</td>
                                        <td style="border-color: black;">{{ data.StartTime | date: 'hh:mm
                                            a':'+0530' }}</td>
                                        <td style="border-color: black;">{{ data.EndTime | date: 'hh:mm
                                            a':'+0530'}}</td>
                                        <td style="border-color: black;">{{ data.ProductionLineNo }}</td>
                                        <td style="border-color: black;">{{ data.WorkShift }}</td>
                                        <td style="border-color: black;">{{ data.ShiftSupervisorName }}</td>
                                        <td style="border-color: black;">{{ data.AddedDate | DatetimeConverter }}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-tab>
                    <nz-tab nzTitle="Downtime Details">
                        <div class="export-buttons" *ngIf="this.DowntimeDataList?.length > 0">
                            <button nz-button nzType="primary" (click)="exportDowntimeToPDF()">
                                <i nz-icon nzType="file-pdf"></i>Export as PDF
                            </button>
                            <button nz-button nzType="default" (click)="exportDowntimeToExcel()">
                                <i nz-icon nzType="file-excel"></i>Export to Excel
                            </button>
                        </div>
                        <nz-alert nzCloseable nzType="info" *ngIf="IsDowntimeNoRecord"
                            nzMessage="No downtime has been recorded for the selected date range."></nz-alert>
                        <nz-divider></nz-divider>
                        <div style="max-width:100%; overflow:scroll;border-color: black;"
                            *ngIf="this.DowntimeDataList?.length > 0">
                            <nz-table id="downtime-table" nzSize="small" style="width: 100%;page-break-inside: auto;"
                                [nzScroll]="{ x: '800px',y:'515px' }" [nzData]="this.DowntimeDataList" #basicTable2
                                [nzLoading]="isTableLoading" nzBordered nzShowPagination="true" nzShowSizeChanger
                                [nzPageSize]="500" [nzPageSizeOptions]=[20,50,100,200,500]
                                [nzShowTotal]="totalDowntimeTemplate" nzShowQuickJumper="true" nzResponsive=true>
                                <thead>
                                    <tr
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto;">
                                        <th nzWidth="50px">S. NO.</th>
                                        <th nzWidth="120px">Reason (Code)</th>
                                        <th nzWidth="80px">Start Time</th>
                                        <th nzWidth="80px">End Time</th>
                                        <th nzWidth="80px">PRD Line Type</th>
                                        <th nzWidth="60px">PRD Line No.</th>
                                        <th nzWidth="80px">Actual Duration</th>
                                        <th nzWidth="80px">Standard Duration</th>
                                        <th nzWidth="80px">Excess Duration</th>
                                        <th nzWidth="80px">Comments</th>
                                        <th nzWidth="80px">Last Updated Date</th>
                                        <th nzWidth="80px">Last Updated By</th>
                                    </tr>
                                    <tr>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of basicTable2.data;"
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto"
                                        [ngStyle]="{'color': data.ExcessDurationMinutes > 0 ? 'red' : 'black', 'font-weight': data.ExcessDurationMinutes > 0 ? 'bold' : 'normal'}">
                                        <td style="border-color: black;">{{data.SerialNo}}</td>
                                        <td style="border-color: black;">{{ data.ReasonName }} ({{ data.ReasonCode }})
                                        </td>
                                        <td style="border-color: black;">{{ data.StartTime.toString() |
                                            DatetimeConverter }}</td>
                                        <td style="border-color: black;">{{ data.EndTime.toString() | DatetimeConverter
                                            }}</td>
                                        <td style="border-color: black;">{{ data.ProductionLineType }}</td>
                                        <td style="border-color: black;">{{ data.ProductionLineNo }}</td>
                                        <td style="border-color: black;">{{ data.ActualDurationFormatted }}</td>
                                        <td style="border-color: black;">{{ data.StandardDurationFormatted }}</td>
                                        <td style="border-color: black;">{{ data.ExcessDurationFormatted }}</td>
                                        <td style="border-color: black;">{{ data.Comments }}</td>
                                        <td style="border-color: black;">{{ data.ModifiedOn ? (data.ModifiedOn |
                                            DatetimeConverter) : (data.CreatedOn | DatetimeConverter) }}</td>
                                        <td style="border-color: black;">{{ data.ModifiedBy ? data.ModifiedBy :
                                            data.CreatedBy }}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-tab>
                    <nz-tab nzTitle="Scheduled Downtime Details">
                        <div class="export-buttons" *ngIf="this.ScheduledDowntimeDataList?.length > 0">
                            <button nz-button nzType="primary" (click)="exportScheduledDowntimeToPDF()">
                                <i nz-icon nzType="file-pdf"></i>Export as PDF
                            </button>
                            <button nz-button nzType="default" (click)="exportScheduledDowntimeToExcel()">
                                <i nz-icon nzType="file-excel"></i>Export to Excel
                            </button>
                        </div>
                        <nz-alert nzCloseable nzType="info" *ngIf="IsScheduledDowntimeNoRecord"
                            nzMessage="No scheduled downtime has been recorded for the selected date range."></nz-alert>
                        <nz-divider></nz-divider>
                        <div style="max-width:100%; overflow:scroll;border-color: black;"
                            *ngIf="this.ScheduledDowntimeDataList?.length > 0">
                            <nz-table id="scheduled-downtime-table" nzSize="small"
                                style="width: 100%;page-break-inside: auto;" [nzScroll]="{ x: '800px',y:'515px' }"
                                [nzData]="this.ScheduledDowntimeDataList" #basicTable2 [nzLoading]="isTableLoading"
                                nzBordered nzShowPagination="true" nzShowSizeChanger [nzPageSize]="500"
                                [nzPageSizeOptions]=[20,50,100,200,500] [nzShowTotal]="totalScheduledDowntimeTemplate"
                                nzShowQuickJumper="true" nzResponsive=true>
                                <thead>
                                    <tr
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto;">
                                        <th nzWidth="50px">S. NO.</th>
                                        <th nzWidth="120px">Reason (Code)</th>
                                        <th nzWidth="80px">Start Time</th>
                                        <th nzWidth="80px">End Time</th>
                                        <th nzWidth="80px">PRD Line Type</th>
                                        <th nzWidth="60px">PRD Line No.</th>
                                        <th nzWidth="80px">Effective From</th>
                                        <th nzWidth="80px">Effective To</th>
                                    </tr>
                                    <tr>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of basicTable2.data;"
                                        style="text-align: left;border-color: black;page-break-inside:avoid; page-break-after:auto">
                                        <td style="border-color: black;">{{data.SerialNo}}</td>
                                        <td style="border-color: black;">{{ data.Reason.ReasonName }} ({{
                                            data.Reason.ReasonCode }})
                                        </td>
                                        <td style="border-color: black;">{{ data.StartDateTime}}
                                        </td>
                                        <td style="border-color: black;">{{ data.EndDateTime}}</td>
                                        <td style="border-color: black;">{{ data.Reason.ProductionLineType }}</td>
                                        <td style="border-color: black;">{{ data.ProductionLineNo }}</td>
                                        <td style="border-color: black;">{{ data.EffectiveFrom | DatetimeConverter
                                            }}</td>
                                        <td style="border-color: black;">{{ data.EffectiveTo ? (data.EffectiveTo |
                                            DatetimeConverter) : 'No End Date' }}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-tab>
                </nz-tabset>
            </nz-tab>
        </nz-tabset>
    </div>
</div>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>
<ng-template #totalDowntimeTemplate let-total>Total {{ this.totalDowntimeCount }} items</ng-template>
<ng-template #totalScheduledDowntimeTemplate let-total>Total {{ this.totalScheduledDowntimeCount }} items</ng-template>
<ng-template #totalUnknownDowntimeTemplate let-total>Total {{ this.totalUnknownDowntimeCount }} items</ng-template>