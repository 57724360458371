import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable, Observer, subscribeOn, Subscription } from 'rxjs';
import { ProductModel, ProductModelExport, ProductTransferModel, GroupedProductTransfer } from 'src/PmsUIApp/Models/ProductModel';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { FormBuilder, UntypedFormControl, FormGroup, ValidationErrors, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel } from '../../Models/MasterModel';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import moment from 'moment';
import { DatetimeConverter } from 'src/PmsUIApp/Services/DatetimeConverter.pipe';
import { CommonService } from 'src/PmsUIApp/Services/CommonService';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';

// Update the interface to match all columns

@Component({
  selector: 'app-product-transfer',
  templateUrl: './producttransfer.component.html',
  styleUrls: ['./producttransfer.component.css']
})
export class ProductTransferComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  ProductList: ProductModel[] = [];
  FilteredProductList: ProductModel[] = [];
  ProductTransferList: ProductTransferModel[] = [];
  ProductTransferListExport: ProductTransferModel[] = [];
  ProductTransferListOriginal: ProductTransferModel[] = [];
  NewProductTransfer: ProductTransferModel = new ProductTransferModel;
  SelectedProductType: string = '';
  SelectedCategory: number = 0;
  SelectedFirstSubCategory: number = 0;
  SelectedSecondSubCategory: number = 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  IsActionBtnLoading: boolean = false;
  MeasureUnits: MeasureUnitModel[] = [];
  typeList = [
    { name: "Raw" },
    { name: "Finished" },
    { name: "Internal-Use" }
  ];
  PopUpTitle: string = "Add New Product";
  searchValue = '';
  visible = false;
  IsSearching = false;
  exportfields: ProductTransferModel[] = [];
  exportoptions = {
    headers: [
      "From Product",
      "To Product",
      "Quantity",
      "Status",
      "Request Reason",
      "Added By",
      "Added Date",
      "Action By",
      "Action Date",
      "Action Remark",
    ]
  };
  fields: ProductTransferModel = new ProductTransferModel();
  count: number;
  AddProductDescription!: UntypedFormGroup;
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  isNew: boolean = true;
  groupedTransfers: GroupedProductTransfer[] = [];
  expandSet = new Set<number>();
  TransferAction: ProductTransferModel = new ProductTransferModel();
  IsTransferActionPopVisible: boolean = false;
  constructor(public http: HttpClient, private alertService: AlertMessageService,
    private modalService: NzModalService, private auth: AuthService, private router: Router, private fb: UntypedFormBuilder,
    private commonService: CommonService, private loader: LoadingService) { }

  onExpandChange(toProductId: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(toProductId);
    } else {
      this.expandSet.delete(toProductId);
    }
  }

  groupTransfers(transfers: ProductTransferModel[]): void {
    const groupMap = new Map<number, GroupedProductTransfer>();

    transfers.forEach(transfer => {
      if (!groupMap.has(transfer.ToProductId)) {
        groupMap.set(transfer.ToProductId, {
          toProduct: transfer.ToProductName,
          toProductId: transfer.ToProductId,
          transfers: []
        });
      }

      // Ensure all properties are properly mapped
      groupMap.get(transfer.ToProductId)?.transfers.push({
        transferId: transfer.TransferId,
        fromProduct: transfer.FromProductName,
        fromProductId: transfer.FromProductId,
        toProduct: transfer.ToProductName,
        toProductId: transfer.ToProductId,
        quantity: transfer.Quantity,
        status: transfer.Status,
        requestReason: transfer.RequestReason,
        addedBy: transfer.AddedByDetails?.Name || '',
        addedDate: new DatetimeConverter().transform(transfer.AddedDate),
        actionRemark: transfer.ActionRemark || '',
        actionBy: transfer.ActionByDetails?.Name || '',
        actionDate: transfer.ActionDate ? new DatetimeConverter().transform(transfer.ActionDate) : ''
      });
    });

    // Convert map to array and sort by ToProductName
    this.groupedTransfers = Array.from(groupMap.values())
      .sort((a, b) => a.toProduct.localeCompare(b.toProduct));

    // Log to verify data
    console.log('Grouped Transfers:', this.groupedTransfers);
  }

  GetAllProductTransfer() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "stock/getproducttransferhistory";
    try {
      this.http.get<ProductTransferModel[]>(url).subscribe({
        next: res => {
          this.ProductTransferList = res;
          this.ProductTransferListOriginal = res;
          this.ProductTransferListExport = res;
          this.groupTransfers(res); // Group the transfers
          this.prepareExportData();
          this.isTableLoading = false;
        },
        error: res => {
          this.count++;
          if (this.count < 2) { this.GetAllProductTransfer(); }
          this.isTableLoading = false;
        }
      });
    } catch (error) {
      this.isTableLoading = false;
      this.alertService.error("Error loading product transfers");
    }
  }

  prepareExportData() {
    this.exportfields = [];
    this.ProductTransferListExport.forEach(x => {
      this.fields = new ProductTransferModel();
      this.fields.FromProductName = x.FromProductName;
      this.fields.ToProductName = x.ToProductName;
      this.fields.Quantity = x.Quantity;
      this.fields.Status = x.Status;
      this.fields.RequestReason = x.RequestReason;
      this.fields.AddedByDetails = x.AddedByDetails;
      this.fields.AddedDate = new DatetimeConverter().transform(x.AddedDate);
      this.fields.ActionByDetails = x.ActionByDetails;
      this.fields.ActionDate = new DatetimeConverter().transform(x.ActionDate);
      this.fields.ActionRemark = x.ActionRemark;
      this.exportfields.push(this.fields);

    })
  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }

  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  SaveProduct() {
    if (!this.Validate(this.NewProductTransfer)) {
      this.isLoading = false
      return;
    }
    // this.NewProduct.ProductType = this.ProductCategoryList.filter(x => x.ProductCategoryId == this.NewProduct.ProductCategoryId)[0].ProductType;
    let url = this.ApiUrl + "stock/requestproductstocktransfer";
    this.http.post<any>(url, this.NewProductTransfer).subscribe({

      next: res => {
        this.alertService.success(res.ResponseBody);
        this.isLoading = this.isVisible = false;
        this.GetAllProductTransfer();
      },
      error: res => {
        if (res.error.StatusCode == 'BadRequest') {
          this.alertService.warning(res.error.ResponseBody); this.isLoading = false;
        }
        else {
          this.alertService.error(res.error.ResponseBody); this.isLoading = this.isVisible = false;
        }
      },

    });

  }

  Validate(model: ProductTransferModel) {
    var Isvalid: boolean = true;
    if (model.FromProductId == 0) {
      this.alertService.error("Select From Product"); Isvalid = false;
    }
    else if (model.ToProductId == 0) {
      this.alertService.error("Select To Product"); Isvalid = false;
    }
    else if (model.RequestReason == '') {
      this.alertService.error("Enter Request Reason"); Isvalid = false;
    }
    else if (model.ToProductId == model.FromProductId) {
      this.alertService.error("To Product and From Product cannot be same"); Isvalid = false;
    }
    
    var sourceProduct = this.ProductList.find(x => x.ProductId == model.FromProductId);
    var destinationProduct = this.ProductList.find(x => x.ProductId == model.ToProductId);
    if(sourceProduct.Unit != destinationProduct.Unit){
      this.alertService.error("Source Product and Destination Product must have the same unit of measure"); Isvalid = false;
    }

    return Isvalid;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Product Transfer";
    this.isNew = true;
    this.NewProductTransfer = new ProductTransferModel;
    this.isVisible = true;
  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveProduct();

  }

  handleCancel(): void {
    this.isVisible = false;
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'product-export' + exportdate,
        this.exportoptions
      );
  }

  search() {
    var res = this.ProductTransferListOriginal;
    const filtered = res.filter((item: ProductTransferModel) => {
      return item?.FromProductName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
        item?.ToProductName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
        item?.Status?.toLowerCase().includes(this.searchValue?.toLowerCase());
    });
    this.ProductTransferList = filtered;
    this.groupTransfers(filtered);
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ProductTransfer, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ProductTransfer, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.ProductTransfer, Responsibility.Edit);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllProductTransfer();
    this.GetAllProduct();
  }
  GetAllProduct() {
    if (this.ProductList.length > 0) {
      return;
    }
    this.commonService.getProductList().subscribe({
      next: res => {
        this.ProductList = res;
        this.loader.hide();
      },
      error: res => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) { this.GetAllProduct(); }
      }
    });
  }
  GetAllProductCategory() {
    if (this.SelectedCategory > 0) {
      this.SelectedCategory = 0;
    }
    if (this.SelectedFirstSubCategory > 0) {
      this.SelectedFirstSubCategory = 0;
    }
    if (this.SelectedSecondSubCategory > 0) {
      this.SelectedSecondSubCategory = 0;
    }
    this.FilteredProductList = [];
    this.commonService.getProductCategoryList().subscribe(res => {
      this.ProductCategoryList = res.filter(x => x.ProductType == this.SelectedProductType);
      this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType);
    }, res => { });
  }
  GetAllFirstCategory(data: any) {
    this.FilteredProductList = [];
    this.SelectedFirstSubCategory = 0;
    this.SelectedSecondSubCategory = 0;
    this.commonService.getProductFirstSubCategoryList().subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);
      this.FilteredProductList = this.ProductList.filter(x => x.ProductCategoryId == this.SelectedCategory);
    }, res => { });
  }
  GetAllSecondCategory(data: any) {
    this.FilteredProductList = [];
    this.SelectedSecondSubCategory = 0;
    this.commonService.getProductSecondSubCategoryList().subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);
      this.FilteredProductList = this.ProductList.filter(x => x.ProductFirstSubCategoryId == this.SelectedFirstSubCategory);
    }, res => { });
  }
  FilterforSecSubCategory(data: any) {
    this.FilteredProductList = this.ProductList.filter(x => x.ProductSecSubCategoryId == data);
  }
  showTransferActionModal(transfer: any, action: string) {
    this.TransferAction.TransferId = transfer.transferId;
    this.TransferAction.FromProductName = transfer.fromProduct;
    this.TransferAction.ToProductName = transfer.toProduct;
    this.TransferAction.Status = action;
    this.IsTransferActionPopVisible = true;
  }
  SaveTransferAction() {
    this.IsActionBtnLoading = true;
    if(this.TransferAction.ActionRemark == ''){
      this.alertService.error("Enter Action Remark");
      this.IsActionBtnLoading = false;
      return;
    }
    let url = this.ApiUrl + "stock/actionproductstocktransfer";
    this.http.post<any>(url, this.TransferAction).subscribe({
      next: res => {
        this.alertService.success(res.ResponseBody);
        this.IsTransferActionPopVisible = false;
        this.IsActionBtnLoading = false;
        this.GetAllProductTransfer();
      },
      error: res => {
        this.alertService.error(res.error.ResponseBody);
        this.IsActionBtnLoading = false;
      }
    });
  }
  handleTransferActionCancel() {
    this.IsTransferActionPopVisible = false;
    this.TransferAction = new ProductTransferModel();
  }
}
