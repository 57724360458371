import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { Modules } from '../../Models/Enums';
import { AdminStoreModel } from '../../Models/MasterModel';
import { ResponsibilityMasterModel } from '../../Models/UserRoleModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-ResponsibilityMaster',
  templateUrl: './ResponsibilityMaster.component.html'
})
export class ResponsibilityMasterComponent {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  Responsibility: ResponsibilityMasterModel = new ResponsibilityMasterModel();
  ResponsibilityList: ResponsibilityMasterModel[] = [];
  ResponsibilityListOriginal: ResponsibilityMasterModel[] = [];
  isLoading: boolean = false;
  isStoreLoading: boolean = false;
  isVisible = false;
  isStoreVisible = false;
  searchValue = '';
  isNew = true;
  isTableLoading: boolean = false;
  StoreList: AdminStoreModel[] = [];
  allChecked = false;
  IsAuth = false;
  ModuleList: string[] = [
    "Dashboard",
    "Dashboard - Welcome",
    "Sales",
    "Sales - Sales Order",
    "Sales - Sales Order Price",
    "Sales - Proforma",
    "Reports",
    "Reports - Sales",
    "Reports - Stock Availability",
    "Reports - Stock Consumption",
    "Reports - Purchase",
    "Reports - Stock",
    "Reports - Store Wise Stock",
    "Reports - Product Wise Stock",
    "Reports - Product Stock History",
    "Reports - Category Wise Stock",
    "Reports - Yield",
    "Reports - Wastage",
    "Reports - Production Planning",
    "Reports - Paste Consumption",
    "Reports - Product Stock Summary",
    "Reports - Production Status",
    "Reports - Post Process",
    "Production - Formulation Code",
    "Production - Pigment MB",
    "Production - Mixing",
    "Production - Workplan List",
    "Production - PreInspection",
    "Production - Start Production",
    "Production - Jumbo Master",
    "Production - Post Process",
    "Production - Final Inspection",
    "Production - Dispatch",
    "Production - ActivityLog",
    "Post Process - Print",
    "Post Process - Embossing",
    "Post Process - Vaccum",
    "Post Process - Tumbling",
    "Post Process - Lacquer",
    "Post Process - Lamination",
    "Purchase Order",
    "Purchase Order - Mark Stock In Transit",
    "Purchase Order - Mark Partial Payment",
    "Purchase Order - Mark Full Payment",
    "Demand",
    "Gate In",
    "Gate Out",
    "Inventory - Stock",
    "Inventory - Opening Stock",
    "Inventory - Add Stock",
    "Inventory - Knitting Division Stock",
    "Inventory - Stock List",
    "Inventory - Inspection",
    "Inventory - Stock Quality Inspection",
    "Inventory - Allocation",
    "Inventory - Rejected",
    "Inventory - Stock Label List",
    "Issue Gate Pass",
    "Out Pass",
    "Issue",
    "Consumption",
    "Costing",
    "Costing - Estimation",
    "Costing - Overhead",
    "Transport",
    "Supplier",
    "Customer",
    "Product",
    "Product - Category",
    "Product - Sub Category",
    "Product - 2nd SubCategory",
    "Product - Transfer",
    "Master - Branch",
    "Master - Department",
    "Master - Tag",
    "Master - Store",
    "Master - Rack",
    "Master - Color",
    "Master - Element",
    "Master - Grain",
    "Master - Thickness",
    "Master - Width",
    "Master - PaymentTerm",
    "Master - Delivery Term",
    "Master - Conversion",
    "Master - Unit",
    "Master - Packaging Type",
    "Master - BankDetails",
    "Master - Designation",
    "Master - Factory Workers",
    "Master - Out Pass Purpose",
    "Admin - Users",
    "Admin - Operations",
    "Admin - Roles",
    "Admin - Users Login History",
    "Admin - General Configuration",
    "Dispatch",
    "Dispatch - Packaging",
    "QuickTools",
    "QuickTools - Measurement Conversion",
    "IoT Devices",
    "IoT Devices - Digital Weight Machine",
    "Reports - Manufacturing Heartbeat",
    "Top Menu More Options",
    "Top Menu More Options - Barcode Scanner",
    "Top Menu More Options - Barcode Manual Entry",
    "Top Menu More Options - External Scanner",
    "Stock - Barcode Label",
    "Notification",
    "Notification - Notification Group",
    "Notification - Production Stages",
    "Notification - WhatsApp Configuration",
    "Notification - WhatsApp Template",
    "Notification - Email Group Mapping",
    "Production Master - Downtime Reason",
    "Production - Downtime"
  ]
  ResponsibilityNameList: string[] = [
    "Add",
    "View",
    "Edit",
    "Delete",
    "Manage",
    "Cancel",
    "Contact",
    "Approval",
    "High Value Approval",
    "Email"
  ]
  count: 0;
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private alertService: AlertMessageService,
    private router: Router,
    private msalService: MsalService,
    public http: HttpClient
  ) { }


  GetAllResponsibility() {
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + "userrolesresponsibility/getallresponsibilitywithroles";
    this.http.get<any>(url).subscribe(
      (res) => {
        this.ResponsibilityList = res;
        this.ResponsibilityListOriginal = res;
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetAllResponsibility();
        }
      }
    );
  }



  GetMyRole(UserName: string) {
    //UserName = 'amit@damasinfo.com';
    let url = this.ApiUrl + 'User/getuserrole/' + UserName;
    this.http.get<any>(url).subscribe(
      (res) => {
        if (res != null) {
          UserInfo.UserRoles = res;
          this.IsAuth = UserInfo.UserRoles.Admin;
        }
      },
      (res) => {
        this.count++
        if (this.count < 2) {
          this.GetMyRole(UserInfo.EmailID);
        }
      }
    );
  }

  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  search(): void {

    var res = this.ResponsibilityListOriginal;
    this.ResponsibilityList = res.filter((item: ResponsibilityMasterModel) => {
      if (
        item?.Module?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;

      }
      else if (
        item?.ResponsibilityName?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      // else if (
      //   item?.ResponsibilityDescripton?.toLowerCase().includes(this.searchValue?.toLowerCase())
      // ) {
      //   return true;  
      else
        return false;
    });
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  ngOnInit() {
    this.IsAuth = this.authService.CheckModule(Modules.AdminOperations);
    //this.GetMyRole(UserInfo.EmailID);
    this.GetAllResponsibility();

    this.validateForm = this.fb.group({
      ResponsibilityCode: [null, [Validators.required]],
    });
  }
  get f() {
    return this.validateForm.controls;
  }
  showModal() {
    this.isNew = true;
    this.isVisible = true;

  }
  OpenEditPop(data: ResponsibilityMasterModel) {
    this.isNew = false;
    this.Responsibility = data;
    this.validateForm.setValue(
      {
        ResponsibilityCode: data.ResponsibilityCode
      }
    )

    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.isStoreVisible = false;
  }

  Save() {
    this.Responsibility.AddedBy = UserInfo.EmailID;
    this.Responsibility.ResponsibilityCode = this.f['ResponsibilityCode'].value;
    let url = this.ApiUrl + "userrolesresponsibility/addupdateresponsibility";
    this.http.post<any>(url, this.Responsibility).subscribe({

      next: res => { console.log(res); this.alertService.success(res); this.isLoading = this.isVisible = false; this.GetAllResponsibility(); },
      error: res => { console.log(res); this.alertService.error(res.error); this.isLoading = this.isVisible = false; },

    });
  }
}
