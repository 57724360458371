<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Product Transfer</nz-page-header-title>
      <nz-page-header-subtitle>Manage your product transfer here</nz-page-header-subtitle>
      <nz-page-header-extra>

        <button nz-button nzType="primary" (click)="showModal()" *ngIf="permission.Add">Add New</button>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <!-- <div style="padding: 8px;float:right">
      <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search name / code / type"
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" [disabled]="IsSearching"
        style="margin-right: 8px;">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div> -->
    <div class="card-container">
      <nz-collapse>
        <nz-collapse-panel *ngFor="let group of groupedTransfers" [nzHeader]="panelHeader" [nzExtra]="extraTemplate">
          <ng-template #panelHeader>
            <div class="panel-header">
              <span class="product-name">{{group.toProduct}}</span>
              <span class="transfer-count">({{group.transfers.length}} transfers)</span>
            </div>
          </ng-template>
          <ng-template #extraTemplate>
            <span class="count-badge">{{group.transfers.length}}</span>
          </ng-template>

          <nz-table #innerTable [nzData]="group.transfers" nzSize="small" [nzShowPagination]="false"
            [nzScroll]="{ x: '1500px' }">
            <thead>
              <tr>
                <th nzWidth="180px" nzLeft>From Product</th>
                <th nzWidth="80px">Quantity</th>
                <th nzWidth="120px">Status</th>
                <th nzWidth="150px">Request Reason</th>
                <th nzWidth="120px">Added By</th>
                <th nzWidth="140px">Added Date</th>
                <th nzWidth="150px">Action Remark</th>
                <th nzWidth="120px">Action By</th>
                <th nzWidth="140px">Action Date</th>
                <th nzWidth="100px" nzRight>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let transfer of innerTable.data">
                <td nzLeft>{{transfer.fromProduct}}</td>
                <td>{{transfer.quantity}}</td>
                <td>{{transfer.status}}</td>
                <td>{{transfer.requestReason}}</td>
                <td>{{transfer.addedBy}}</td>
                <td>{{transfer.addedDate}}</td>
                <td>{{transfer.actionRemark}}</td>
                <td>{{transfer.actionBy}}</td>
                <td>{{transfer.actionDate}}</td>
                <td nzRight>
                  <button nz-button nz-dropdown [nzDropdownMenu]="menu">
                    Action <i nz-icon nzType="down"></i>
                  </button>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item (click)="showTransferActionModal(transfer, 'Rejected')"
                        *ngIf="transfer.status == 'Approval Pending' && permission.Edit">Reject</li>
                      <li nz-menu-item (click)="showTransferActionModal(transfer, 'Approved')"
                        *ngIf="transfer.status == 'Approval Pending' && permission.Edit">Approve</li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>

  <nz-modal [(nzVisible)]="isVisible" [nzStyle]="{ top: '20px' }" [nzTitle]="modalTitle" [nzContent]="modalContent"
    [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
    <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

    <ng-template #modalContent>
      <form nz-form>
        <nz-form-item>
          <nz-form-label [nzSpan]="8" nzRequired>Product Type</nz-form-label>
          <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Type">
            <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType" name="ProductType"
              (ngModelChange)="GetAllProductCategory()" nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.typeList;" [nzValue]="s.name" [nzLabel]="s.name"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="8" nzRequired>Category</nz-form-label>
          <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Category">
            <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="SelectedCategory" name="ProductCategoryId"
              nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllFirstCategory($event)">
              <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                [nzLabel]="s.ProductCategory"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="8">Sub Category</nz-form-label>
          <nz-form-control [nzSpan]="14" nzErrorTip="Select Product First Category">
            <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="SelectedFirstSubCategory"
              name="ProductFirstSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose"
              (ngModelChange)="GetAllSecondCategory($event)">
              <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId"
                [nzLabel]="s.ProductFirstSubCategory"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="8">2nd Sub Category</nz-form-label>
          <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Second Category">
            <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="SelectedSecondSubCategory"
              name="ProductSecSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose"
              (ngModelChange)="FilterforSecSubCategory($event)">
              <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId"
                [nzLabel]="s.ProductSecSubCategory"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="8" nzRequired>From Product</nz-form-label>
          <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Type">
            <nz-select class="form-select mb-2" nzShowSearch nzSize="default" [(ngModel)]="NewProductTransfer.FromProductId"
              name="FromProductId" nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                [nzLabel]="s.ProductName"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="8" nzRequired>To Product</nz-form-label>
          <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Type">
            <nz-select class="form-select mb-2" nzShowSearch nzSize="default" [(ngModel)]="NewProductTransfer.ToProductId"
              name="ToProductId" nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                [nzLabel]="s.ProductName"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired [nzSpan]="8">Reason</nz-form-label>
          <nz-form-control [nzSpan]="14" nzErrorTip="Only 400 characters allowed.">
            <nz-textarea-count [nzMaxCharacterCount]="400">
              <textarea nz-input [(ngModel)]="NewProductTransfer.RequestReason" rows="5"
                [ngModelOptions]="{standalone: true}"></textarea>
            </nz-textarea-count>
          </nz-form-control>
        </nz-form-item>
      </form>
    </ng-template>

    <ng-template #modalFooter>

      <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
      <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isLoading">Save Product</button>
    </ng-template>
  </nz-modal>
</div>

<nz-modal [(nzVisible)]="IsTransferActionPopVisible" [nzStyle]="{ top: '20px' }" [nzWidth]="500"
  [nzTitle]="modalTitleDispatch" [nzContent]="modalContentDispatch" [nzFooter]="modalFooterDispatch"
  (nzOnCancel)="handleTransferActionCancel()">
  <ng-template #modalTitleDispatch>Product Transfer Action</ng-template>

  <ng-template #modalContentDispatch>
    <label>Product Transfer From: <b>{{this.TransferAction.FromProductName}} </b> To:
      <b>{{this.TransferAction.ToProductName}} </b></label>
    <p></p>
    <label style="color: red;"><b>* Remark is mandatory</b></label>
    <nz-form-item>
      <nz-form-control nzErrorTip="Only 180 characters allowed.">
        <nz-textarea-count [nzMaxCharacterCount]="180">
          <textarea nz-input class="form-control mb-2" [(ngModel)]="TransferAction.ActionRemark"
            [ngModelOptions]="{standalone: true}" value="" rows="3"></textarea>
        </nz-textarea-count>
      </nz-form-control>
    </nz-form-item>
    <p>NOTE: If successful, the product stock in all stores will be transferred from the source product to the
      destination product and the source product will be deleted.</p>
  </ng-template>

  <ng-template #modalFooterDispatch>
    <button nz-button nzType="primary" (click)="SaveTransferAction()" [nzLoading]="IsActionBtnLoading">Submit</button>
    <button nz-button nzType="default" (click)="handleTransferActionCancel()" [nzLoading]="IsActionBtnLoading">Cancel</button>
  </ng-template>
</nz-modal>