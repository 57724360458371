export enum ESalesOrderStatus {
  NotYet = 0,
  WorkPlan = 1,
  Inspection = 2,
  RawMaterialRequested = 3,
  RawMaterialIssued = 4,
  ProductionStarted = 5,
  Mixing = 6,
  InJumbo = 7,
  MoveToPostProcess = 8,
  PrintAssigned = 9,
  PrintInProcess = 10,
  PrintCompleted = 11,
  EmbossingAssigned = 12,
  EmbossingInProcess = 13,
  EmbossingCompleted = 14,
  VacuumAssigned = 15,
  VacuumInProcess = 16,
  VacuumCompleted = 17,
  LacquerAssigned = 18,
  LacquerInProcess = 19,
  LacquerCompleted = 20,
  TumblingAssigned = 21,
  TumblingInProcess = 22,
  TumblingCompleted = 23,
  JumboInspection = 24,
  MoveToDispatch = 25,
  PartialDispatchReady = 26,
  PartialDispatchCompleted = 27,
  DispatchReady = 28,
  DispatchCompleted = 30,
  LiningOrderMerged = 101
}

export enum Modules {
  DashboardWelcome = 'Dashboard - Welcome',
  SalesOrder = 'Sales - Sales Order',
  SalesOrderPrice = 'Sales - Sales Order Price',
  SalesOrderProforma = 'Sales - Proforma',
  FormulationCode = 'Production - Formulation Code',
  PigmentMB = 'Production - Pigment MB',
  Mixing = 'Production - Mixing',
  Workplan = 'Production - Workplan List',
  PreInspection = 'Production - PreInspection',
  Jumbo = 'Production - Jumbo Master',
  StartProduction = 'Production - Start Production',
  ActivityLog = 'Production - ActivityLog',
  PostProcess = 'Production - Post Process',
  FinalInspection = 'Production - Final Inspection',
  Print = 'Post Process - Print',
  Embossing = 'Post Process - Embossing',
  Vaccum = 'Post Process - Vaccum',
  Tumbling = 'Post Process - Tumbling',
  Lacquer = 'Post Process - Lacquer',
  PurchaseOrder = 'Purchase Order',
  PurchaseOrderInTransit = 'Purchase Order - Mark Stock In Transit',
  PurchaseOrderMarkPartialPayment = 'Purchase Order - Mark Partial Payment',
  PurchaseOrderMarkFullPayment = 'Purchase Order - Mark Full Payment',
  Demand = 'Demand',
  GateIn = 'Gate In',
  GateOut = 'Gate Out',
  Stock = 'Reports - Stock',
  OpeningStock = 'Inventory - Opening Stock',
  AddStock = 'Inventory - Add Stock',
  KnittingDivisionStock = 'Inventory - Knitting Division Stock',
  StockList = 'Inventory - Stock List',
  Inspection = 'Inventory - Inspection',
  StockQualityInspection = 'Inventory - Stock Quality Inspection',
  Allocation = 'Inventory - Allocation',
  Rejected = 'Inventory - Rejected',
  OutPass = 'Out Pass',
  IssueGatePass = 'Issue Gate Pass',
  Issue = 'Issue',
  Consumption = 'Consumption',
  Costing = 'Costing',
  CostingOverhead = 'Costing - Overhead',
  CostEstimation = 'Costing - Estimation',
  Transport = 'Transport',
  Supplier = 'Supplier',
  Customer = 'Customer',
  Product = 'Product',
  ProductTransfer = 'Product - Transfer',
  ProductCategory = 'Product - Category',
  ProductSubCategory = 'Product - Sub Category',
  Product2ndSubCategory = 'Product - 2nd SubCategory',
  Branch = 'Master - Branch',
  Department = 'Master - Department',
  Tag = 'Master - Tag',
  Store = 'Master - Store',
  Rack = 'Master - Rack',
  Color = 'Master - Color',
  Element = 'Master - Element',
  Grain = 'Master - Grain',
  Thickness = 'Master - Thickness',
  Width = 'Master - Width',
  PaymentTerm = 'Master - PaymentTerm',
  DeliveryTerm = 'Master - Delivery Term',
  Conversion = 'Master - Conversion',
  Unit = 'Master - Unit',
  PackagingType = 'Master - Packaging Type',
  BankDetails = 'Master - BankDetails',
  OutPassPurpose = 'Master - Out Pass Purpose',
  Designation = 'Master - Designation',
  FactoryEmployee = 'Master - Factory Workers',
  AdminUsers = 'Admin - Users',
  AdminOperations = 'Admin - Operations',
  AdminRoles = 'Admin - Roles',
  AdminGeneralConfiguration = 'Admin - General Configuration',
  JumboDispatch = 'JumboDispatch',
  DispatchPackaging = 'Dispatch - Packaging',
  ReportsStockAvailability = 'Reports - Stock Availability',
  ReportsStockConsumption = 'Reports - Stock Consumption',
  ReportsPurchase = 'Reports - Purchase',
  ReportsStoreWiseStock = 'Reports - Store Wise Stock',
  ReportsProductWiseStock = 'Reports - Product Wise Stock',
  ReportsProductStockHistory = 'Reports - Product Stock History',
  ReportsCategoryWiseStock = 'Reports - Category Wise Stock',
  ReportsYield = 'Reports - Yield',
  ReportsWastage = 'Reports - Wastage',
  ReportsPostProcess = 'Reports - Post Process',
  ReportsProductionPlanning = 'Reports - Production Planning',
  ReportsProductionStatus = 'Reports - Production Status',
  ReportsProductStockSummary = 'Reports - Product Stock Summary',
  ReportsPasteConsumption = 'Reports - Paste Consumption',
  ReportsMfgHeartbeat = 'Reports - Manufacturing Heartbeat',
  IoTDevicesDigitalWeight = 'IoT Devices - Digital Weight Machine',
  QuickTools = 'QuickTools',
  StockBarcodeLabel = 'Stock - Barcode Label',
  StockLabelList = 'Inventory - Stock Label List',
  Notification = 'Notification',
  NotificationGroup = 'Notification - Notification Group',
  NotificationStages = 'Notification - Production Stages',
  NotificationWhatsappConfiguration = 'Notification - WhatsApp Configuration',
  NotificationWhatsappTemplate = 'Notification - WhatsApp Template',
  NotificationEmailGroupMapping = 'Notification - Email Group Mapping',
  ProductionDowntimeReason = 'Production Master - Downtime Reason',
  ProductionDowntime = 'Production - Downtime'
}

export enum Responsibility {
  Add = 'Add',
  View = 'View',
  Delete = 'Delete',
  Edit = 'Edit',
  Manage = 'Manage',
  Cancel = 'Cancel',
  Approval = 'Approval',
  HighValueApproval = 'High Value Approval',
  Email = 'Email'
}

export enum PMSPurchaseOrderStatus {
  New = "New",
  Revised = "Revised",
  Approved = "Approved",
  Active = "Active",
  Complete = "Complete",
  ReOpen = "Re-Open",
  Blocked = "Blocked",
  Unblock = "Unblock",
  Cancelled = "Cancelled",
  PartialReceived = "Partial Received",
  PartialPaymentCompleted = "Partial Payment Completed",
  FullPaymentCompleted = "Full Payment Completed",
  InTransit = "In Transit",
  GateInCompleted = "Gate In Completed",
  StoreAcknowledged = "Store Acknowledged",
}

export enum OutpassStatus {
  OnHold = "On Hold",
  Rejected = "Rejected",
  Cancelled = "Cancelled",
  ReturnCompleted = "Return Completed"
}

export enum ScannerMode {
  Closed,
  OverlayScanner,
  ManualEntry,
  ExternalDevice
}