export class ProductModel
    {
        ProductId : number= 0;
        ProductName : string= '';
        ProductCode : string= '';
        Unit : string= '';
        AvgGsm : string= '';
        ProductType: string = '';
        ProductCategoryId: number = 0;
        ProductFirstSubCategoryId: number = 0;
        ProductSecSubCategoryId: number = 0;
        ProductCategory: string = '';
        ProductFirstSubCategory: string = '';
        ProductSecSubCategory: string = '';
        MinimumQuantity: number = 0;
        WidthInMeter: number = 0;
        ProductDescription?:string=""
        SupplierId?:number=0
        Barcode?:string=""
        BatchNumber?:string=""
        ManufacturedDate?:string=''
        ExpiryDate?:string=''
        Sku?:string=""
        Quantity?:number=0
        PricePerUnit?:number=0
        StoreId?:number=0
        RackId?:number=0
        Invoice?:any
        Grade?:string=""
        StockProductAllocation: any;
        StoreName?:string=""
        StockDate?:string=""
       
    }


    export class ProductModelExport
    {
         ProductName : string= '';
        ProductCode : string= '';
        ProductType: string = '';
        Unit : string= '';
        AvgGsm : string= '';
        WidthInMeter : number = 0;
        MinimumQuantity: number = 0;
        ProductCategory: string = '';
        ProductFirstSubCategory: string = '';
        ProductSecSubCategory: string = '';
      }
export class productItem
{
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  ProductID: number = 0;
  ProductName: string = '';
  UnitID: number = 0;
  Unit: string = '';
  AvgGsm: string = '';
  Rate: number = 0;
  Quantity: number = 0;
  Amount: number = 0;
  IGST: number = 0;
  Grade: string = '';
  Currency: string = '';
  ImportCode: string = '';
}

export class ProductTransferModel {
  TransferId: number = 0;
  FromProductId: number = 0;
  FromProductName: string = '';
  ToProductId: number = 0;
  ToProductName: string = '';
  Quantity: number = 0;
  Status: string = '';
  RequestReason: string = '';
  AddedByDetails: any;
  AddedDate: string = '';
  ActionRemark: string = '';
  ActionByDetails: any;
  ActionDate: string = '';
}

export interface GroupedProductTransfer {
  toProduct: string;
  toProductId: number;
  transfers: {
    transferId: number;
    fromProduct: string;
    fromProductId: number;
    toProduct: string;
    toProductId: number;
    quantity: number;
    status: string;
    requestReason: string;
    addedBy: string;
    addedDate: string;
    actionRemark: string;
    actionBy: string;
    actionDate: string;
  }[];
}