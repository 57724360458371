import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { DemandModel } from '../../Models/DemandModel';
import { DemandPopService } from '../../Demand/services/DemandPopService';
import { PurchaseOrderModel, PurchaseOrderProductModel } from '../../Models/PurchaseOrderModel';
import { LoadingService } from '../../Services/loadingService';
import { ProductModel } from '../../Models/ProductModel';
import { PoDrawerService } from '../../Features/PoDrawerView/services/PoDrawerService';
import { PoTimelineService } from '../../Services/PoTimeLineService';
import { DemandEventService } from '../../Demand/services/DemandEventService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-DemandList',
  templateUrl: './DemandList.component.html'
})
export class DemandListComponent implements OnInit, OnDestroy {
  ApiUrl = environment.Api_Url;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  DemandList: DemandModel[] = [];
  DemandListOriginal: DemandModel[] = [];
  PurchaseOrder!: PurchaseOrderModel;
  PurchaseOrderList: PurchaseOrderModel[] = [];
  IsBtnVisible: boolean = false;
  permission = {
    View: false,
    Add: false,
    Manage: false,
    CreatePO: false,
    ViewPO: false,
  }
  SelectedProductType: string = '';
  SelectedStatus: string = '';
  FilteredProductList: ProductModel[] = [];
  ProductList: ProductModel[] = [];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  count: number;
  IsTimelineOpen = false;
  demandRefreshSubscription: Subscription;
  constructor(private loader: LoadingService, private demandService: DemandPopService, private fb: UntypedFormBuilder, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService,
    private timeLine: PoTimelineService,
    private poDrawer: PoDrawerService,
    private demandEventService: DemandEventService) {
  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Demand, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Demand, Responsibility.Add);
    this.permission.Manage = this.auth.CheckResponsibility(Modules.Demand, Responsibility.Manage);
    this.permission.CreatePO = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Add);
    this.permission.ViewPO = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.View);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    console.log(this.permission)
    this.GetAllDemand();
    this.GetAllProducts();
    this.GetAllProductCategory();
    this.demandRefreshSubscription = this.demandEventService.refreshDemandList$.subscribe(() => {
      this.GetAllDemand();
    });
  }
  ngOnDestroy() {
    this.demandRefreshSubscription.unsubscribe();
  }
  GetAllDemand() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "purchaseorder/getalldemands";
    this.http.get<DemandModel[]>(url).subscribe(res => {
      this.DemandListOriginal = this.DemandList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllDemand();
      }
    });
  }
  showModal() {
    this.demandService._registerCallback(this.GetAllDemand());
    this.demandService.IsFromOutside = false;
    this.demandService.show();
  }
  OnCheckChanged() {
    var selectedDemandId = this.DemandList.filter((item) => item.IsChecked).map(
      (finalResult) => {
        return {
          DemandId: finalResult.DemandId,
        };
      }
    );
    localStorage.setItem("DemandIds", JSON.stringify(selectedDemandId));
    if (selectedDemandId.length > 0)
      this.IsBtnVisible = true
    else
      this.IsBtnVisible = false;
  }
  CheckCreatePOPermission() {
    if (this.permission.CreatePO && this.IsBtnVisible) {
      return true
    }
    else
      return false
  }
  CreatePO() {
    this.router.navigate(['/home/po/adddemand/0']);
  }
  OpenViewPop(data: any) {
    this.isVisible = true;
    this.PurchaseOrder = data;
    this.poDrawer.Poid = data.Poid;
    this.poDrawer.show();
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  ChangeStatus(data: DemandModel, status: string) {
    this.loader.show();
    data.Status = status;
    let url = this.ApiUrl + "purchaseorder/changedemandstatus";
    this.http.post<any>(url, data).subscribe({
      next: res => {
        this.alertService.success("Demand " + status);
        this.loader.hide();
      },
      error: res => { this.alertService.error(res.error); this.loader.hide(); },
    });
  }
  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
  }
  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProducts();
        }
      }
    );
  }
  GetAllProductCategory() {
    let url = this.ApiUrl + 'productcategory/getallproductcategoriesforlisting';
    this.http.get<ProductCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductCategoryList = res;
      },
      (res) => { }
    );
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductCategoryId == this.CategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == data
        );

      },
      (res) => { }
    );
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductFirstSubCategoryId == this.FirstCategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductSecSubCategoryList = res.filter(
          (x) => x.ProductFirstSubCategoryId == data
        );
      },
      (res) => { }
    );
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductSecSubCategoryId == this.SecondCategoryID
    );
  }
  onSelectedProductChange(data: any) {
    var fp = this.FilteredProductList.filter(
      (x) => x.ProductId == data
    )[0];
    //this.NewStockProduct.Unit = fp.Unit;
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == this.CategoryID
        );
        this.FirstCategoryID = fp.ProductFirstSubCategoryId;
        let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
          (res) => {
            this.ProductSecSubCategoryList = res.filter(
              (x) => x.ProductFirstSubCategoryId == this.FirstCategoryID
            );
            this.SecondCategoryID = fp.ProductSecSubCategoryId;
          },
          (res) => { }
        );
      },
      (res) => { }
    );
  }
  GetReport() {
    var res = this.DemandListOriginal;
    this.DemandList = res.filter((item: DemandModel) => item.ProductType == this.SelectedProductType || this.SelectedProductType == '')
      .filter((item: DemandModel) => item.ProductCategoryId == this.CategoryID || this.CategoryID == 0 || this.CategoryID == null)
      .filter((item: DemandModel) => item.ProductFirstSubCategoryId == this.FirstCategoryID || this.FirstCategoryID == 0 || this.FirstCategoryID == null)
      .filter((item: DemandModel) => item.ProductSecSubCategoryId == this.SecondCategoryID || this.SecondCategoryID == 0 || this.SecondCategoryID == null)
      .filter((item: DemandModel) => item.ProductId == this.selectedProduct || this.selectedProduct == 0 || this.selectedProduct == null)
      .filter((item: DemandModel) => item.Status == this.SelectedStatus || this.SelectedStatus == null || this.SelectedStatus == '')
    
    //this.DemandList = res.filter((item: DemandModel) => item.ProductId == this.selectedProduct || this.selectedProduct == 0 || this.selectedProduct == null);
  }
  setDemandCellColor(data: DemandModel) {
    // Get the current date
    const currentDate = new Date();
    const addeddate = new Date(data.AddedDate).getTime();

    // Calculate the difference in time between the current date and the AddedDate
    const timeDiff = currentDate.getTime() - addeddate;

    // Convert the time difference from milliseconds to days
    const diffDays = timeDiff / (1000 * 3600 * 24);

    // Check if the difference is greater than 3 days
    if (diffDays > 5 && data.Status == 'Active') {
      return '#ff0000'; // Red color for dates older than 3 days
    } else {
      return ''; // Default color for dates within 3 days
    }
  }
  OpenTimelinePop(data: any): void {
    this.IsTimelineOpen = false;
    this.timeLine.Poid = data.Poid;
    this.timeLine.show();

    // this.TimeLineObject = data;
    // this.GetAllActivityLog(data.Poid);
  }
}
